const INITIAL_STATE = {
  error: null,
  loading: null,
  data: []
};

const SearchPlatform = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "SEARCH_PLATFORM_REQUEST":
      return { loading: true, error: false };
    case "SEARCH_PLATFORM_ERROR":
      return { loading: false, error: true };
    case "SEARCH_PLATFORM_SUCCESS":
      return { loading: false, error: false, data: action.payload };
    case "SEARCH_PLATFORM_RESET":
      return { loading: null, error: null, data: [] };
    default:
      return state;
  }
};

export default SearchPlatform;
