'use client'

import { useEffect, useState } from 'react'
import { Dialog, DialogBackdrop, DialogPanel, TransitionChild } from '@headlessui/react'
import { AcademicCapIcon, Bars3Icon, CalendarIcon, ChartPieIcon, ChatBubbleBottomCenterIcon, ChatBubbleLeftRightIcon, CreditCardIcon, DocumentDuplicateIcon, FolderArrowDownIcon, FolderIcon, HomeIcon, PaintBrushIcon, ShoppingBagIcon, ShoppingCartIcon, Square3Stack3DIcon, StarIcon, TagIcon, UserIcon, UsersIcon, XMarkIcon, } from '@heroicons/react/24/outline'
import { useHistory } from 'react-router-dom/cjs/react-router-dom'
import Logo from "../../../assets/imgs/logo01.png";
//import { getTranslation } from '../../../translations'
import { useParams } from 'react-router-dom'
import { translations } from "../../../utils/language";
import { useDispatch, useSelector } from 'react-redux'

const classItemActive = "bg-[#005AFF] text-white"
const classItemInactive = "text-gray-700 hover:bg-[#005AFF] hover:text-white"
const classItem = "group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6"

const classItemActiveIcon = "text-white"
const classItemInactiveIcon = "text-gray-400 group-hover:text-white"
const classItemIcon = "h-6 w-6 shrink-0"



function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function LayoutNew(props) {
    const [sidebarOpen, setSidebarOpen] = useState(false)
    const history = useHistory()
    const dispatch = useDispatch()

    const language = useSelector((state) => state.reducerLanguage);

    useEffect(() => {
        console.log("language", language)
    }, [language])

    useEffect(() => {
        const storageLanguage = localStorage.getItem("language");
        if (storageLanguage) {
            //setLanguage(storageLanguage);
            dispatch({
                type: "SAGA_LIST_LANGUAGE",
                payload: {
                    language: storageLanguage,
                },
            });
        } else {
            //setLanguage("pt");
            dispatch({
                type: "SAGA_LIST_LANGUAGE",
                payload: {
                    language: "pt",
                },
            });
        }
    }, [localStorage, dispatch]);


    const [urlProductsReviews, setUrlProductsReviews] = useState("")
    const [urlProductsQuestions, setUrlProductsQuestions] = useState("")

    const params = useParams();

    useEffect(() => {
        //console.log("params", params)
        setUrlProductsReviews(`/produtos/${params.id}/avaliacoes`)
        setUrlProductsQuestions(`/produtos/${params.id}/perguntas`)
    }, [params])


    //const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language)

    const getTitleMobile = () => {
        switch (window.location.pathname) {
            case "/":
                return "Dashboard"
            case "/produtos":
                return translations["Produtos"][language.data]
            case "/midias":
                return translations["Mídias"][language.data]
            case "/pedidos":
                return translations["pedidos"][language.data]
            case "/passo-a-passo":
                return translations["Passo a passo"][language.data]
            case "/ajuda":
                return translations["Ajuda"][language.data]
            case "/personalizar":
                return translations["Personalizar"][language.data]
            case "/passo-a-passo":
                return translations["passo-a-passo"][language.data]
            case "/integracoes":
                return translations["Integrações"][language.data]
            case "/pagamentos":
                return translations["Pagamentos"][language.data]
            default:
                return ""
        }
    }


    return (
        <>
            <div>
                <Dialog open={sidebarOpen} onClose={setSidebarOpen} className="relative z-50 lg:hidden">
                    <DialogBackdrop
                        transition
                        className="fixed inset-0 bg-gray-900/80 transition-opacity duration-300 ease-linear data-[closed]:opacity-0"
                    />

                    <div className="fixed inset-0 flex">
                        <DialogPanel
                            transition
                            className="relative mr-16 flex w-full max-w-xs flex-1 transform transition duration-300 ease-in-out data-[closed]:-translate-x-full"
                        >
                            <TransitionChild>
                                <div className="absolute left-full top-0 flex w-16 justify-center pt-5 duration-300 ease-in-out data-[closed]:opacity-0">
                                    <button type="button" onClick={() => setSidebarOpen(false)} className="-m-2.5 p-2.5">
                                        <span className="sr-only">Close sidebar</span>
                                        <XMarkIcon aria-hidden="true" className="h-6 w-6 text-white" />
                                    </button>
                                </div>
                            </TransitionChild>
                            {/* Sidebar component, swap this element with another sidebar if you like */}
                            <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-2">
                                <div style={{ gap: "16px", color: "#005AFF" }} className="flex h-16 shrink-0 items-center font-semibold">
                                    <img
                                        style={{ width: "24px" }}
                                        src={Logo}
                                        alt="Dijital"
                                    />
                                    <label style={{ fontSize: "16px" }}>Dijital</label>
                                </div>
                                <nav className="flex flex-1 flex-col">
                                    <ul role="list" className="flex flex-1 flex-col gap-y-7">
                                        <li>
                                            <ul role="list" className="-mx-2 space-y-1">

                                                <li key={"Dashboard"}>
                                                    <a href={"javascript:void(0);"} onClick={() => { history.push("/") }} className={classNames(window.location.pathname == "/" ? classItemActive : classItemInactive, classItem,)}>
                                                        <HomeIcon aria-hidden="true" className={classNames(window.location.pathname == "/" ? classItemActiveIcon : classItemInactiveIcon, classItemIcon,)} />
                                                        {"Dashboard"}
                                                    </a>
                                                </li>

                                                <li key={"Avaliações"}>
                                                    <a href={"javascript:void(0);"} onClick={() => { history.push("/produtos") }} className={classNames(window.location.pathname == "/produtos" ? classItemActive : classItemInactive, classItem,)}>
                                                        <ShoppingBagIcon aria-hidden="true" className={classNames(window.location.pathname == "/produtos" ? classItemActiveIcon : classItemInactiveIcon, classItemIcon,)} />
                                                        {translations["Produtos"][language.data]}
                                                    </a>
                                                </li>

                                                <li key={"item.name"}>
                                                    <a href={"javascript:void(0);"} onClick={() => { history.push("/midias") }} className={classNames(window.location.pathname == "/midias" ? classItemActive : classItemInactive, classItem,)}>
                                                        <FolderArrowDownIcon aria-hidden="true" className={classNames(window.location.pathname == "/midias" ? classItemActiveIcon : classItemInactiveIcon, classItemIcon,)} />
                                                        {translations["Mídias"][language.data]}
                                                    </a>
                                                </li>

                                                <li key={"item.name"}>
                                                    <a href={"javascript:void(0);"} onClick={() => { history.push("/pedidos") }} className={classNames(window.location.pathname == "/pedidos" ? classItemActive : classItemInactive, classItem,)}>
                                                        <ShoppingCartIcon aria-hidden="true" className={classNames(window.location.pathname == "/pedidos" ? classItemActiveIcon : classItemInactiveIcon, classItemIcon,)} />
                                                        {translations["Pedidos"][language.data]}
                                                    </a>
                                                </li>







                                                <li key={"item.name"}>
                                                    <a href={"javascript:void(0);"} onClick={() => { history.push("/passo-a-passo") }} className={classNames(window.location.pathname == "/passo-a-passo" ? classItemActive : classItemInactive, classItem,)}>
                                                        <AcademicCapIcon aria-hidden="true" className={classNames(window.location.pathname == "/passo-a-passo" ? classItemActiveIcon : classItemInactiveIcon, classItemIcon,)} />
                                                        {translations["Passo a passo"][language.data]}
                                                    </a>
                                                </li>

                                                <li key={"item.name"}>
                                                    <a href={"javascript:void(0);"} onClick={() => { history.push("/ajuda") }} className={classNames(window.location.pathname == "/ajuda" ? classItemActive : classItemInactive, classItem,)}>
                                                        <UserIcon aria-hidden="true" className={classNames(window.location.pathname == "/ajuda" ? classItemActiveIcon : classItemInactiveIcon, classItemIcon,)} />
                                                        {translations["Ajuda"][language.data]}
                                                    </a>
                                                </li>

                                            </ul>
                                        </li>
                                        <li>
                                            <div className="text-xs font-semibold leading-6 text-gray-400">Configurações</div>
                                            <ul role="list" className="-mx-2 mt-2 space-y-1">
                                                <li key={"item.name"}>
                                                    <a href={"javascript:void(0);"} onClick={() => { history.push("/personalizar") }} className={classNames(window.location.pathname == "/personalizar" || window.location.pathname == urlProductsReviews || window.location.pathname == urlProductsQuestions ? classItemActive : classItemInactive, classItem,)}>
                                                        <PaintBrushIcon aria-hidden="true" className={classNames(window.location.pathname == "/personalizar" || window.location.pathname == urlProductsReviews || window.location.pathname == urlProductsQuestions ? classItemActiveIcon : classItemInactiveIcon, classItemIcon,)} />
                                                        {translations["Personalizar"][language.data]}
                                                    </a>
                                                </li>


                                                <li key={"item.name"}>
                                                    <a href={"javascript:void(0);"} onClick={() => { history.push("/integracoes") }} className={classNames(window.location.pathname == "/integracoes" ? classItemActive : classItemInactive, classItem,)}>
                                                        <Square3Stack3DIcon aria-hidden="true" className={classNames(window.location.pathname == "/integracoes" ? classItemActiveIcon : classItemInactiveIcon, classItemIcon,)} />
                                                        {translations["Integrações"][language.data]}
                                                    </a>
                                                </li>

                                                <li key={"item.name"}>
                                                    <a href={"javascript:void(0);"} onClick={() => { history.push("/pagamentos") }} className={classNames(window.location.pathname == "/pagamentos" ? classItemActive : classItemInactive, classItem,)}>
                                                        <CreditCardIcon aria-hidden="true" className={classNames(window.location.pathname == "/pagamentos" ? classItemActiveIcon : classItemInactiveIcon, classItemIcon,)} />
                                                        {translations["Pagamentos"][language.data]}
                                                    </a>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </DialogPanel>
                    </div>
                </Dialog>

                {/* Static sidebar for desktop */}
                <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
                    {/* Sidebar component, swap this element with another sidebar if you like */}
                    <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6">
                        <div style={{ gap: "16px", color: "#005AFF" }} className="flex h-16 shrink-0 items-center font-semibold">
                            <img
                                style={{ height: "36px" }}
                                src={Logo}
                                alt="Dijital"
                            />
                            <label style={{ fontSize: "16px" }}>Dijital</label>
                        </div>
                        <nav className="flex flex-1 flex-col">
                            <ul role="list" className="flex flex-1 flex-col gap-y-7">
                                <li>
                                    <ul role="list" className="-mx-2 space-y-1">

                                        <li key={"Dashboard"}>
                                            <a href={"javascript:void(0);"} onClick={() => { history.push("/") }} className={classNames(window.location.pathname == "/" ? classItemActive : classItemInactive, classItem,)}>
                                                <HomeIcon aria-hidden="true" className={classNames(window.location.pathname == "/" ? classItemActiveIcon : classItemInactiveIcon, classItemIcon,)} />
                                                {"Dashboard"}
                                            </a>
                                        </li>

                                        <li key={"Avaliações"}>
                                            <a href={"javascript:void(0);"} onClick={() => { history.push("/produtos") }} className={classNames(window.location.pathname == "/produtos" ? classItemActive : classItemInactive, classItem,)}>
                                                <ShoppingBagIcon aria-hidden="true" className={classNames(window.location.pathname == "/produtos" ? classItemActiveIcon : classItemInactiveIcon, classItemIcon,)} />
                                                {translations["Produtos"][language.data]}
                                            </a>
                                        </li>

                                        <li key={"item.name"}>
                                            <a href={"javascript:void(0);"} onClick={() => { history.push("/midias") }} className={classNames(window.location.pathname == "/midias" ? classItemActive : classItemInactive, classItem,)}>
                                                <FolderArrowDownIcon aria-hidden="true" className={classNames(window.location.pathname == "/midias" ? classItemActiveIcon : classItemInactiveIcon, classItemIcon,)} />
                                                {translations["Mídias"][language.data]}
                                            </a>
                                        </li>

                                        <li key={"item.name"}>
                                            <a href={"javascript:void(0);"} onClick={() => { history.push("/pedidos") }} className={classNames(window.location.pathname == "/pedidos" ? classItemActive : classItemInactive, classItem,)}>
                                                <ShoppingCartIcon aria-hidden="true" className={classNames(window.location.pathname == "/pedidos" ? classItemActiveIcon : classItemInactiveIcon, classItemIcon,)} />
                                                {translations["Pedidos"][language.data]}
                                            </a>
                                        </li>







                                        <li key={"item.name"}>
                                            <a href={"javascript:void(0);"} onClick={() => { history.push("/passo-a-passo") }} className={classNames(window.location.pathname == "/passo-a-passo" ? classItemActive : classItemInactive, classItem,)}>
                                                <AcademicCapIcon aria-hidden="true" className={classNames(window.location.pathname == "/passo-a-passo" ? classItemActiveIcon : classItemInactiveIcon, classItemIcon,)} />
                                                {translations["Passo a passo"][language.data]}
                                            </a>
                                        </li>

                                        <li key={"item.name"}>
                                            <a href={"javascript:void(0);"} onClick={() => { history.push("/ajuda") }} className={classNames(window.location.pathname == "/ajuda" ? classItemActive : classItemInactive, classItem,)}>
                                                <UserIcon aria-hidden="true" className={classNames(window.location.pathname == "/ajuda" ? classItemActiveIcon : classItemInactiveIcon, classItemIcon,)} />
                                                {translations["Ajuda"][language.data]}
                                            </a>
                                        </li>

                                    </ul>
                                </li>
                                <li>
                                    <div className="text-xs font-semibold leading-6 text-gray-400">Configurações</div>
                                    <ul role="list" className="-mx-2 mt-2 space-y-1">

                                        <li key={"item.name"}>
                                            <a href={"javascript:void(0);"} onClick={() => { history.push("/personalizar") }} className={classNames(window.location.pathname == "/personalizar" || window.location.pathname == urlProductsReviews || window.location.pathname == urlProductsQuestions ? classItemActive : classItemInactive, classItem,)}>
                                                <PaintBrushIcon aria-hidden="true" className={classNames(window.location.pathname == "/personalizar" || window.location.pathname == urlProductsReviews || window.location.pathname == urlProductsQuestions ? classItemActiveIcon : classItemInactiveIcon, classItemIcon,)} />
                                                {translations["Personalizar"][language.data]}
                                            </a>
                                        </li>


                                        <li key={"item.name"}>
                                            <a href={"javascript:void(0);"} onClick={() => { history.push("/integracoes") }} className={classNames(window.location.pathname == "/integracoes" ? classItemActive : classItemInactive, classItem,)}>
                                                <Square3Stack3DIcon aria-hidden="true" className={classNames(window.location.pathname == "/integracoes" ? classItemActiveIcon : classItemInactiveIcon, classItemIcon,)} />
                                                {translations["Integrações"][language.data]}
                                            </a>
                                        </li>

                                        <li key={"item.name"}>
                                            <a href={"javascript:void(0);"} onClick={() => { history.push("/pagamentos") }} className={classNames(window.location.pathname == "/pagamentos" ? classItemActive : classItemInactive, classItem,)}>
                                                <CreditCardIcon aria-hidden="true" className={classNames(window.location.pathname == "/pagamentos" ? classItemActiveIcon : classItemInactiveIcon, classItemIcon,)} />
                                                {translations["Pagamentos"][language.data]}
                                            </a>
                                        </li>

                                        {/* <li key={"Personalizar"}>
                                            <a href={"javascript:void(0);"} onClick={() => { history.push("/personalizar") }} className={classNames(window.location.pathname.split("/")[1] == "personalizar" ? classItemActive : classItemInactive, classItem,)}>
                                                <PaintBrushIcon aria-hidden="true" className={classNames(window.location.pathname.split("/")[1] == "personalizar" ? classItemActiveIcon : classItemInactiveIcon, classItemIcon,)} />
                                                {"translation.sidebar.personalizar"}
                                            </a>
                                        </li>

                                        <li key={"Integrações"}>
                                            <a href={"javascript:void(0);"} onClick={() => { history.push("/integracoes") }} className={classNames(window.location.pathname == "/integracoes" ? classItemActive : classItemInactive, classItem,)}>
                                                <Square3Stack3DIcon aria-hidden="true" className={classNames(window.location.pathname == "/integracoes" ? classItemActiveIcon : classItemInactiveIcon, classItemIcon,)} />
                                                {"translation.sidebar.integracoes"}
                                            </a>
                                        </li>

                                        <li key={"Pagamentos"}>
                                            <a href={"javascript:void(0);"} onClick={() => { history.push("/pagamentos") }} className={classNames(window.location.pathname == "/pagamentos" ? classItemActive : classItemInactive, classItem,)} >
                                                <CreditCardIcon aria-hidden="true" className={classNames(window.location.pathname == "/pagamentos" ? classItemActiveIcon : classItemInactiveIcon, classItemIcon,)} />
                                                {"translation.sidebar.assinatura"}
                                            </a>
                                        </li> */}

                                    </ul>
                                </li>
                                {/* <li style={{display: "none"}} className="-mx-6 mt-auto">
                                    <a
                                        href="javascript:void(0);"
                                        className="flex items-center gap-x-4 px-6 py-3 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-50"
                                    >
                                        <img
                                            alt="javascript:void(0);"
                                            src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                                            className="h-8 w-8 rounded-full bg-gray-50"
                                        />
                                        <span className="sr-only">Your profile</span>
                                        <span aria-hidden="true">Tom Cook</span>
                                    </a>
                                </li> */}
                            </ul>
                        </nav>
                    </div>
                </div>

                <div className="sticky top-0 z-40 flex items-center gap-x-6 bg-white px-4 py-4 shadow-sm sm:px-6 lg:hidden">
                    <button type="button" onClick={() => setSidebarOpen(true)} className="-m-2.5 p-2.5 text-gray-700 lg:hidden">
                        <span className="sr-only">Open sidebar</span>
                        <Bars3Icon aria-hidden="true" className="h-6 w-6" />
                    </button>
                    <div className="flex-1 text-sm font-semibold leading-6 text-gray-900">{getTitleMobile()}</div>
                    {/* <a href="javascript:void(0);">
                        <span className="sr-only">Your profile</span>
                        <img
                            alt="javascript:void(0);"
                            src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                            className="h-8 w-8 rounded-full bg-gray-50"
                        />
                    </a> */}
                </div>

                <main className="py-10 lg:pl-72 bg-[#F2F2FD]">
                    <div className="px-4 pt-5 sm:px-6 lg:px-8">
                        {props.children}
                    </div>
                </main>
            </div>
        </>
    )
}
