import React from 'react'
import { Container, DateFilter, ItemDateFilter, MenuOption, OptionsUpdate, SearchPlatform } from './styled'
import Body from '../../../../components/Body'
import SideBar from '../../../../components/SideBar'
import Header from '../../../../components/Header'
import { useSelector } from 'react-redux'
import LoadingLogo from '../../../../components/Loading-logo'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Paginate } from '../../../products/pages/home-new/styled'
import { PaginateNav } from '../../../products/pages/home-new/styled'
import { PaginateInfor } from '../../../products/pages/home-new/styled'
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from "react-icons/md";
import { useState } from 'react'
import { BlockProducts, ContainerLine, ContainerSearch, Line, SubTitle, TextLine } from '../home/styled'
import Title from '../../../../components/Title'
import { CheckCustom, SearchCustom, SectionActions, SectionBar, SectionCheck, TableMedias, TableMediasHeader } from '../../../medias/pages/home-new/styled'
import { BsCheck, BsEmojiSmile, BsHash, } from "react-icons/bs";
import { Refresh2, SearchNormal1, Trash } from "iconsax-react";
import { translations } from '../../../../utils/language'
import CardPedidos from '../../components/card-pedidos'
import AlertCloseBoss from '../../../../components/alert-close-boss'
import ModalInforVideo from '../../../../components/modal-infor-video'
import ModalSearchPlatform from '../../components/modal-search-platform'

import IconPedidos from "../../../../assets/imgs/icon-pedidos.svg";

import { RxUpdate } from "react-icons/rx";
import { IoMdArrowDropdown } from 'react-icons/io'
import ModalSearchAllPlatform from '../../components/Modal-Search-All-Platform'
import ModalSearchAllPlatformDefault from '../../components/modal-search-all-platform-default'
import HelpClick from '../../../../components/HelpClick'
import LayoutNew from '../../../../components/tailwind/layout-new'
import { ContainerNew } from '../../../dashboard/pages/home-new/styled'

const HomeNew = () => {

    const sales = useSelector((state) => state.reducerSales);
    const dispatch = useDispatch()
    const [search, setSearch] = useState("");
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(20);
    const language = useSelector((state) => state.reducerLanguage);
    const [visibleModalInfor, setVisibleModalInfor] = useState(false)
    const [seletedSales, setSeletedSales] = useState([])
    const [isChekedAll, setIsChekedAll] = useState(false)

    const [dateInicioFilter, setDateInicioFilter] = useState("")
    const [dateFimFilter, setDateFimFilter] = useState("")

    const [visibleModalSearchPlatform, setVisibleModalSearchPlatform] = useState(false)
    const [visibleModalSearchAllPlatform, setVisibleModalSearchAllPlatform] = useState(false)

    const [visibleMenuUpdate, setVisibleMenuUpdate] = useState(false)

    // // useEffect(() => {
    // //     dispatch({ type: "SAGA_LIST_SALES", payload: { perPage: perPage, page: page, search: search, iniDate: dateInicioFilter, findate: dateFimFilter }, });
    // // }, []);

    // useEffect(() => {
    //     dispatch({ type: "SAGA_LIST_SALES", payload: { perPage: perPage, page: page, search: search, iniDate: dateInicioFilter, findate: dateFimFilter }, });
    // }, []);

    useEffect(() => {
        dispatch({ type: "SAGA_LIST_SALES", payload: { perPage: perPage, page: page, search: search, iniDate: dateInicioFilter, finDate: dateFimFilter }, });
    }, [dateFimFilter, dateInicioFilter, page]);


    const countItens = () => {
        let result = 0
        if (page == sales?.data?.meta?.last_page) result = sales?.data?.meta?.total
        else result = page * perPage
        return result
    }

    const changeCheck = () => {
        if (isChekedAll) {
            setSeletedSales([])
            setIsChekedAll(false)
        } else {
            setSeletedSales(sales.data.data)
            setIsChekedAll(true)
        }
    }

    const goSearch = () => {
        dispatch({ type: "SAGA_LIST_SALES", payload: { search: search, perPage: perPage, page: 1, iniDate: dateInicioFilter, finDate: dateFimFilter }, });
    }

    const addSales = (item) => {
        setSeletedSales([...seletedSales, item])
    }

    const removeSales = (item) => {
        setIsChekedAll(false)
        setSeletedSales(seletedSales.filter((value) => {
            return item.id != value.id
        }))
    }

    const sendEmailMulti = () => {
        dispatch({
            type: "SAGA_RESEND_MULTI_EMAIL",
            payload: { orders: seletedSales },
        });
        setSeletedSales([])
        setIsChekedAll(false)
    }

    const closeMenu = (event) => { setVisibleMenuUpdate(false); };

    useEffect(() => {

        if (visibleMenuUpdate) {
            window.addEventListener("mouseup", closeMenu);
            return () => {
                window.removeEventListener("mouseup", closeMenu);
            };
        }
    }, [visibleMenuUpdate]);

    return (

        <LayoutNew>
            <ContainerNew>



                <ModalInforVideo visible={visibleModalInfor} setVisibleModal={setVisibleModalInfor} video={`https://www.youtube.com/embed/${language.data == "es" ? "XQaU_7MrLGs" : "v3XLEG9-82c"}`} />
                <ModalSearchPlatform goSearch={goSearch} visible={visibleModalSearchPlatform} setVisibleModal={setVisibleModalSearchPlatform} />
                <ModalSearchAllPlatform goSearch={goSearch} visible={visibleModalSearchAllPlatform} setVisibleModal={setVisibleModalSearchAllPlatform} />
                <ModalSearchAllPlatformDefault goSearch={goSearch} visible={visibleModalSearchAllPlatform} setVisibleModal={setVisibleModalSearchAllPlatform} />


                {typeof JSON.parse(localStorage.getItem("depoimentos@login"))?.integration == "undefined" || JSON.parse(localStorage.getItem("depoimentos@login"))?.integration == null ?
                    <ContainerLine>
                        <BlockProducts>
                            <Title marginBottom="16px">
                                Pedidos
                            </Title>
                            <SubTitle>
                                {translations["Veja os pedidos feitos nas loja integradas"][language.data]}
                            </SubTitle>
                            <Line style={{ display: "flex", alignItems: "center", justifyContent: "center", marginBottom: 12, }} >
                                <TextLine>
                                    <BsEmojiSmile size={25} style={{ marginRight: "5px" }} />
                                    {translations["sales-nao-pedidos"][language.data]}
                                </TextLine>
                            </Line>
                        </BlockProducts>
                    </ContainerLine> :

                    <>
                        <ContainerLine>
                            <BlockProducts>
                                <Title marginBottom="16px">
                                    {/* {translations["Pedidos"][language.data]} */}
                                    Pedidos
                                </Title>
                                <ContainerSearch>
                                    <SubTitle>
                                        {translations["Veja os pedidos feitos nas loja integradas"][language.data]}
                                    </SubTitle>
                                </ContainerSearch>

                                <TableMedias>
                                    <TableMediasHeader>
                                        <SectionCheck>
                                            <CheckCustom onClick={() => { changeCheck() }}>
                                                {isChekedAll && <BsCheck size={20} />}
                                            </CheckCustom>
                                        </SectionCheck>
                                        <SectionBar>
                                            <SectionActions style={{ flex: "1" }}>
                                                <span onClick={() => { sendEmailMulti() }}>
                                                    <Refresh2 size={16} />
                                                    <label>  {translations["sales-reenviar-emails"][language.data]}</label>
                                                </span>
                                            </SectionActions>

                                            <div style={{ display: "flex", gap: "20px" }}>
                                                <DateFilter>
                                                    <ItemDateFilter>
                                                        <label>De:</label>
                                                        <input id="date" type="date" value={dateInicioFilter} onChange={(e) => { setDateInicioFilter(e.target.value) }} />
                                                    </ItemDateFilter>
                                                    <ItemDateFilter>
                                                        <label> {translations["sales-ate"][language.data]}:</label>
                                                        <input id="date" type="date" value={dateFimFilter} onChange={(e) => { setDateFimFilter(e.target.value) }} />
                                                    </ItemDateFilter>

                                                </DateFilter>
                                                <SearchCustom>
                                                    <input onKeyPress={(e) => { e.key == "Enter" && goSearch() }} type="text" placeholder={translations["sales-pesquisar"][language.data]} value={search} onChange={(e) => { setSearch(e.target.value) }} />
                                                    <span onClick={() => { goSearch() }}>
                                                        <SearchNormal1 color="#ffffff" size={20} />
                                                    </span>
                                                </SearchCustom>

                                                <OptionsUpdate onClick={() => { setVisibleMenuUpdate(!visibleMenuUpdate) }}>
                                                    <RxUpdate color='#222' size={14} />
                                                    <label>{translations["sales-atualizar"][language.data]}</label>
                                                    <IoMdArrowDropdown />
                                                    <MenuOption visible={visibleMenuUpdate}>
                                                        <label onClick={() => { setVisibleModalSearchPlatform(true); setVisibleMenuUpdate(false) }}>
                                                            <BsHash size={20} />
                                                            N° pedido
                                                        </label>
                                                        <label
                                                            onClick={() => {
                                                                dispatch({ type: "SAGA_SEARCH_PLATFORM_ALL", payload: JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.platform }); closeMenu()
                                                            }}
                                                        >
                                                            <img src={IconPedidos} />
                                                            {translations["sales-todos-pedidos"][language.data]}
                                                        </label>
                                                    </MenuOption>
                                                </OptionsUpdate>

                                            </div>
                                        </SectionBar>
                                    </TableMediasHeader>
                                    {sales?.data?.data?.map((item) => { return (<CardPedidos seletedSales={seletedSales} add={addSales} remove={removeSales} item={item} />) })}
                                </TableMedias>

                                {sales.loading || sales.data?.length == 0 ?
                                    <div style={{ marginTop: "50px", marginBottom: "50px", }}>
                                        <LoadingLogo />
                                    </div> :
                                    <Paginate>
                                        <PaginateNav>
                                            <span onClick={() => { if (page != 1) setPage(page - 1) }}>
                                                <MdKeyboardArrowLeft size={35} color={sales?.data?.meta?.current_page == 1 ? "gray" : "#19B674"} />
                                            </span>
                                            <label>{sales?.data?.meta?.current_page}</label>
                                            <span onClick={() => { if (page < sales?.data?.meta?.last_page) setPage(page + 1) }}>
                                                <MdKeyboardArrowRight size={35} color={sales?.data?.meta?.last_page == page ? "gray" : "#19B674"} />
                                            </span>
                                        </PaginateNav>
                                        <PaginateInfor>
                                            <label>Total {countItens()} - {sales.data?.meta?.total}</label>
                                        </PaginateInfor>
                                    </Paginate>}

                            </BlockProducts>
                        </ContainerLine>
                    </>
                }

                {
                    !sales.loading &&
                    <div style={{ display: "flex", padding: "20px 0px", justifyContent: "center" }} >
                        <div onClick={() => { setVisibleModalInfor(true) }}>
                            <HelpClick text={translations["alert-pedidos"][language.data]} />
                        </div>
                    </div>
                }
            </ContainerNew>
        </LayoutNew>
    )
}

export default HomeNew