import React from 'react'
import { BoxControl, CheckCustom, Container, ItemAction, SectionActions, SectionIcon, SectionInfor } from './styled'
import { BsFilePdf } from "react-icons/bs";
import { AiOutlinePaperClip } from "react-icons/ai";
import { useEffect } from 'react';
import { sizeFiles } from '../../../../utils/convert';
import { Edit2, Trash, DocumentDownload, ExportSquare } from "iconsax-react";
import ModalConfirmation from '../../../../components/Modal-Confirmation';
import { useState } from 'react';
import { BsCheck } from "react-icons/bs";
import { useDispatch, useSelector } from 'react-redux';
import ModalRenomearMidia from '../modal-renomear-midia';
import { Toast } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { translations } from '../../../../utils/language';

const CardMedia = (props) => {

    const [visibleModalDelete, setvisibleModalDelete] = useState(false)
    const [visibleModalRenomear, setVisibleModalRenomear] = useState(false)
    const [isCheked, setisCheked] = useState(false)
    const dispatch = useDispatch()
    const language = useSelector((state) => state.reducerLanguage);




    useEffect(() => {
        if (props.seletedMedias.filter(value => value.id == props.item.id).length > 0) {
            setisCheked(true)
        } else {
            setisCheked(false)
        }
    }, [props.seletedMedias])


    const deleteMedia = () => {
        //dispatch({ type: "SAGA_DELETE_MEDIA", payload: { id: props.item.id, }, });
        dispatch({ type: "SAGA_DELETE_MULTI_MEDIA", payload: { id: props.item.id, } })
    }

    const downloadMedia = () => {
        window.open(`${process.env.REACT_APP_API_URL}/download/${JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.id}/${props.item.id}`, '_self')
    }

    const renameMedia = (name, url) => {
        if (props.item.type == "url") {
            dispatch({ type: "SAGA_UPDATE_NEW_MEDIA", payload: { id: props.item.id, media: url, media_name: name, }, });
        } else {
            dispatch({ type: "SAGA_UPDATE_NEW_MEDIA", payload: { id: props.item.id, media_name: name, }, });
        }

    }

    const changeCheck = () => {
        if (isCheked) {
            props.remove(props.item)
            setisCheked(false)
        } else {
            props.add(props.item)
            setisCheked(false)
        }
    }






    return (
        <BoxControl>
            <ModalRenomearMidia renameMedia={renameMedia} item={props.item} visible={visibleModalRenomear} setVisible={setVisibleModalRenomear} />
            <CheckCustom style={{marginRight: "10px"}} onClick={() => { changeCheck() }}>
                {isCheked && <BsCheck size={20} />}
            </CheckCustom>
            <Container>
                <ModalConfirmation
                    confirmation={deleteMedia}
                    setVisibleModal={setvisibleModalDelete}
                    visible={visibleModalDelete}
                    title={`${translations["medias-deseja-deletar"][language.data]}  ${props.item?.type == "url" ? props.item?.media : props.item?.media_name} ?`}
                />
                <SectionIcon style={{ position: "relative" }}>
                    {props.getIconFile(
                        props.item.type == "url" ? "url" : props?.item?.media_name?.split(".")[props?.item?.media_name?.split(".").length - 1],
                        props.item.type == "url" ? "url" : props?.item?.media_name?.split(".")[props?.item?.media_name?.split(".").length - 1]
                    )}
                </SectionIcon>

                <SectionInfor>
                    <span>
                        <label style={{ wordBreak: "break-word" }}>
                            {props.item.media_name?.lenght == 0 || props.item.media_name == null ? props.item.media : props.item.media_name}
                            <span>{translations["medias-em"][language.data]} {props.item.quantity_linked_products} {translations["medias-produtos"][language.data]}</span>
                        </label>
                        <p>{props.item.size != null && sizeFiles(parseInt(props.item.size))}</p>
                    </span>
                    <label>{new Date(props.item.created_at).toLocaleDateString('pt-Br', { dateStyle: 'short' })}</label>
                </SectionInfor>

                <SectionActions>
                    <ItemAction color={"#20D489"} onClick={() => { props.item.type == "url" ? window.open(props.item.media, '_blank') : downloadMedia() }}>
                        {props.item.type == "url" ? <ExportSquare size={16} /> : <DocumentDownload size={16} />}
                        <label>{props.item.type == "url" ? "Acessar URL" : translations["medias-baixar-arquivo"][language.data]}</label>
                    </ItemAction>
                    <ItemAction color={"#F4C17A"} onClick={() => { setVisibleModalRenomear(true) }}  >
                        <Edit2 size={16} />
                        <label>{translations["medias-renomear-arquivo"][language.data]}</label>
                    </ItemAction>
                    <ItemAction color={"#F13F6C"} onClick={() => { setvisibleModalDelete(true) }}>
                        <Trash size={16} />
                        <label>{translations["medias-deletar-arquivo"][language.data]}</label>
                    </ItemAction>
                </SectionActions>
            </Container>
        </BoxControl>

    )
}

export default CardMedia