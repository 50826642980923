import { put, call, delay } from "redux-saga/effects";
import {
  POST,
  GET,
  DELETE,
  POST_CUSTOM,
  POST_MEDIA,
  PUT,
  UPLOAD_FILE_S3,
} from "../../../../services/api";
import { toast } from "react-toastify";
import { FiAlertTriangle } from "react-icons/fi";

export function* listMedia(action) {

  console.log("listMedia action", action)

  try {
    yield put({ type: "MEDIAS_REQUEST" });
    const result = yield call(GET, {
      endpoint: `/medias/pagination?per_page=${action.payload.perPage}&page=${action.payload.page}&search=${action.payload.search}&type=${action.payload.type == undefined ? "" : action.payload.type}`,
    });
    yield put({ type: "MEDIAS_SUCCESS", payload: result.data.data });
  } catch (err) {

  }
}

export function* updateMedia(action) {



  try {
    const result = yield call(PUT, {
      endpoint: `/upload_media`,
      data: action.payload,
    });
    if (result === false) {
      toast.error("Erro ao atualizar");
    } else {
      toast.success("Sucesso ao atualizar");
      try {
        yield put({ type: "MEDIAS_REQUEST" });
        const result = yield call(GET, {
          endpoint: `/media/?per_page=${action.payload.perPage}`,
        });
        yield action.function();
        yield put({ type: "MEDIAS_SUCCESS", payload: result.data.data });
      } catch (err) {
        alert(err);
      }
    }
  } catch (err) {
    alert(err);
  }
}

export function* uploadMedia(action) {

  // 
  // yield put({ type: "MEDIAS_UPLOAD_REQUEST", })
  // //yield delay(5000);
  // const result = yield call(POST, { endpoint: `/upload_media`, data: action.payload })
  // if (result === false) {
  //   yield put({ type: "MEDIAS_UPLOAD_ERROR" })
  // } else {
  //   yield put({ type: "MEDIAS_UPLOAD_SUCCESS", payload: result.data.data })
  // }



  yield put({ type: "MEDIAS_UPLOAD_REQUEST", })
  try {
    let index = [];

    for (var i = 0; i < action?.payload?.media.length; i++) {
      const dataMedia = yield call(POST_CUSTOM, {
        data: {
          media: action.payload.media[i].file,
          limit: action.payload.limit,
          message: action.payload.message,
          media_name: `${action?.payload?.media[i].file.name}`
            ? `${action?.payload?.media[i].file.name}`
            : "Sem nome",
        },
      });
      if (dataMedia?.data?.id) {
        const result = action.payload.variants(dataMedia?.data?.id);
        index.push(result);
        if (index.length == action?.payload?.media.length) {
          action.payload.navigation();
          toast.success("Sucesso ao subir a mídia");
        }

        yield put({ type: "MEDIAS_UPLOAD_SUCCESS", payload: dataMedia })
      } else {
        toast.error(dataMedia.description);
        action.payload.navigation();
      }
    }

  } catch (err) {
    alert(err);
    toast.error("Erro ao subir a mídia");
    yield put({ type: "MEDIAS_UPLOAD_ERROR" })
  }
}

export function* uploadUrl(action) {



  try {
    let index = [];
    for (var i = 0; i < action.payload.url.length; i++) {
      const dataMedia = yield call(POST_CUSTOM, {
        data: {
          url:
            action.payload.url[i].name.includes("http://") ||
              action.payload.url[i].name.includes("https://")
              ? action.payload.url[i].name
              : `http://${action.payload.url[i].name}`,
          limit: action.payload.limit,
          message: action.payload.message,
        },
      });
      if (dataMedia?.data?.id) {
        const result = action.payload.variants(dataMedia?.data?.id);
        index.push(result);
        if (index.length == action.payload.url.length) {
          action.payload.navigation();
          toast.success("Sucesso ao subir a mídia");
        }
      } else {
        toast.error(dataMedia.description);
        action.payload.navigation();
        // alert(JSON.stringify(dataMedia.data.description));
      }
    }
    // yield action?.payload?.media.map(async (item) => {

    // });
  } catch (err) {
    //alert(err);
    toast.error("Erro ao subir a mídia");
  }
}

export function* listProductsByMedia(action) {
  try {
    const result = yield call(GET, {
      endpoint: `/products_by_media/${action.payload.id}`,
    });
    yield put({
      type: "PRODUCTS_BY_MEDIAS_SUCCESS",
      payload: result.data,
    });
  } catch (err) {
    alert(err);
  }
}

export function* listMediaByProducts(action) {
  try {
    const result = yield call(GET, {
      endpoint: `/media/${action.payload.id}`,
    });
    yield put({
      type: "MEDIAS_BY_PRODUCTS_SUCCESS",
      payload: result.data,
    });
  } catch (err) {
    alert(err);
  }
}

export function* listMediaByVariants(action) {
  try {
    const result = yield call(GET, {
      endpoint: `/media_by_variant/${action.payload.id}`,
    });
    yield put({
      type: "MEDIAS_BY_PRODUCTS_SUCCESS",
      payload: result.data,
    });
  } catch (err) {
    alert(err);
  }
}

export function* deleteMediaById(action) {
  try {
    yield call(DELETE, {
      endpoint: `/delete_media/${action.payload.id}`,
    });
    toast.success("Mídia deletada com sucesso!");
    const result = yield call(GET, {
      endpoint: `/media`,
    });
    yield put({ type: "MEDIAS_SUCCESS", payload: result.data.data });
  } catch (err) {
    toast.error("Erro ao deletar mídia!");
  }
}


export function* downloadMedia(action) {
  //yield put({ type: "MEDIAS_DOWNLOAD_REQUEST" });
  //yield delay(200);
  const result = yield call(GET, { endpoint: `/download/${action.payload.id}` });
  //
}

export function* deleteMedias(action) {

  if (typeof action.payload.id != "undefined") {
    yield put({ type: "MEDIAS_DELETE_REQUEST" });
    const result = yield call(DELETE, { endpoint: `/delete_media/${action.payload.id}`, });
    if (result == false) {
      yield put({ type: "MEDIAS_DELETE_ERROR" });
    } else {
      yield put({ type: "MEDIAS_DELETE_SUCCESS", payload: result.data });
      yield put({ type: "SAGA_LIST_MEDIA", payload: { search: "", perPage: 20, page: 1, }, })
    }
  } else {
    yield put({ type: "MEDIAS_DELETE_REQUEST" });
    const result = yield call(POST, { endpoint: `/medias`, data: action.payload, });
    if (result == false) {
      yield put({ type: "MEDIAS_DELETE_ERROR" });
    } else {
      yield put({ type: "MEDIAS_DELETE_SUCCESS", payload: result.data });
      yield put({ type: "SAGA_LIST_MEDIA", payload: { search: "", perPage: 20, page: 1, }, })
    }
  }

}

export function* updateMediaNew(action) {

  yield put({ type: "MEDIAS_UPDATE_REQUEST" });
  const result = yield call(PUT, { endpoint: `/upload_media`, data: action.payload, });
  if (result == false) {
    yield put({ type: "MEDIAS_UPDATE_ERROR" });
  } else {
    yield put({ type: "MEDIAS_UPDATE_SUCCESS", payload: result.data });
    yield put({ type: "SAGA_LIST_MEDIA", payload: { search: "", perPage: 20, page: 1, }, })
  }

}


export function* uploadFile(action) {

  console.log("upload file", action.payload)
  yield put({ type: "UPLOAD_FILES_REQUEST" });
  const result = yield call(GET, { endpoint: `/getSignedUrl` });
  console.log("result", result)
  if (result == false) {
    yield put({ type: "UPLOAD_FILES_ERROR" });
  } else {
    yield put({ type: "UPLOAD_FILES__SUCCESS", payload: result.data });
    const result2 = yield call(UPLOAD_FILE_S3, { data: action.payload, endpoint: result.data.data });
    console.log("result2", result2)
  }

}