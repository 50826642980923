import { ArrowTopRightOnSquareIcon, CloudArrowDownIcon, MagnifyingGlassIcon } from "@heroicons/react/24/outline"
import { useEffect } from "react"
import { BarFilters, BarFiltersIcon, BarFiltersLeft, DivProduct, Search } from "../../home-table/styled"
import FilterOrder from "../../components/filters-order";

const people = [
    { name: 'Lindsay Walton', title: 'Front-end Developer', email: 'lindsay.walton@example.com', role: 'Member' },
    // More people...
]

export default function TableDownload(props) {

    useEffect(() => {
        console.log("props teste", props)
    }, [props])


    return (
        <div style={{ maxWidth: "1440px", margin: "0 auto", backgroundColor: "#fff" }}>
            <div className="px-4 pt-5 sm:px-6 lg:px-8 ">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h1 className="text-base font-semibold leading-6 text-gray-900">{props.config.checkout_message_title}</h1>
                        <p className="mt-2 text-sm text-gray-700">
                            {props.config.checkout_message}
                        </p>
                    </div>
                </div>
                <BarFilters>
                    <span style={{ display: "flex", justifyContent: "space-between" }}>
                        <BarFiltersLeft>
                            <Search>
                                <span>
                                    <MagnifyingGlassIcon style={{ width: "20px" }} />
                                </span>
                                <input onKeyPress={(e) => { e.key == "Enter" && props.setsearch(e.target.value) }} type='text' placeholder='Pesquisa por produto ou mídia' value={props.search} onChange={(e) => { props.setsearch(e.target.value) }} />
                            </Search>
                        </BarFiltersLeft>
                        {/* <BarFiltersIcon>
                            <div style={{ display: "flex" }}>
                                <FilterOrder />
                            </div>
                        </BarFiltersIcon> */}
                    </span>
                </BarFilters>
                <div className="mt-8 flow-root ">
                    <div className="-mx-0 -my-0 overflow-x-auto sm:-mx-0 lg:-mx-0">
                        <div className="inline-block min-w-full py-2 align-middle">
                            <table className="min-w-full divide-y divide-gray-300">
                                <thead>
                                    <tr>
                                        <th
                                            scope="col"
                                            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 lg:pl-8"
                                        >
                                            Produto
                                        </th>
                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                            Mídia
                                        </th>
                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                            Tipo
                                        </th>
                                        <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6 lg:pr-8">
                                            <span className="sr-only">Edit</span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200 ">
                                    {props.filterProducts.map((item) => (
                                        <tr style={{ cursor: "pointer" }} onClick={() => { props.download(item) }} key={"person.email"}>
                                            <div style={{ minHeight: "80px" }} className="flex items-center gap-x-4 ">
                                                {item.product_image != "" && <img alt="" src={item.product_image} className="h-8 w-8 rounded bg-gray-800" />}
                                                <DivProduct style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }} className="truncate text-sm font-medium leading-6 text-gray">{item?.product_name}</DivProduct>
                                            </div>
                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                <div className="flex gap-x-3">
                                                    <div style={{ width: "content", maxWidth: "500px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }} className="font-mono text-sm leading-6 text-gray-700">{item?.media_name}</div>
                                                    {item.size != null && <div className={props.getColorMedia()}>
                                                        {props.sizeFiles(item.size)}
                                                    </div>}
                                                </div>
                                            </td>
                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                <div className="flex gap-x-3">
                                                    <div className={props.getColorType()}>
                                                        {item?.type.toUpperCase()}
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 lg:pr-8">
                                                <button
                                                    onClick={() => { props.download(item) }}
                                                    type="button"
                                                    className={props.getColorButton()}
                                                >
                                                    {item.type != "url" ?
                                                        < CloudArrowDownIcon aria-hidden="true" className="-ml-0.5 h-5 w-5" /> :
                                                        < ArrowTopRightOnSquareIcon aria-hidden="true" className="-ml-0.5 h-5 w-5" />
                                                    }
                                                    {item.type != "url" ? props.config?.checkout_button : props.config?.checkout_button_link}
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
