import Modal from 'react-modal';
import { Body, Card, Container, Title } from './styled';
import { CloseCircle } from 'iconsax-react';
import { CloudArrowUpIcon, LinkIcon } from '@heroicons/react/24/outline';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/solid';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: "white",
        padding: "0px",
        margin: "0px",
        borderRadius: "4px"
    },
    overlay: {
        backgroundColor: "rgba(10,23,55,0.5)",
        zIndex: "99999999"
    }
};



const ModalSelectActions = (props) => {



    return (
        <Modal
            isOpen={props.visible}
            style={customStyles}
            //ariaHideApp={false}
            onRequestClose={() => { props.setVisible(false) }}
        >
            <Container>
                <Title>
                    <label>Selecione como deseja anexar</label>
                    <CloseCircle style={{cursor: "pointer"}} color="gray" size={24} onClick={() => { props.setVisible(false) }} />
                </Title>
                <Body>
                    <Card onClick={() => {
                        props.setVisible(false)
                        props.setVisibleModalUpload(true)
                
                    }}>
                        <CloudArrowUpIcon style={{ width: "32px", color: "#222" }} />
                        <p>Upload de arquivo</p>
                        <label style={{ color: "gray" }}>
                            Envie seus arquivos digitais, como documentos, imagens e PDFs, de forma fácil e segura.
                        </label>
                    </Card>
                    <Card onClick={() => {
                        props.setVisible(false)
                        props.setVisibleModalVincular(true)
                 
                    }}>
                        <LinkIcon style={{ width: "32px", color: "#222" }} />
                        <p>Vincular arquivo</p>
                        <label style={{ color: "gray" }}>
                            Vincule arquivos e urls já enviados aos seus produtos para uma gestão mais organizada e eficiente.
                        </label>
                    </Card>
                    <Card onClick={() => {
                        props.setVisible(false)
                        props.setVisibleModalConectar(true)

                    }}>
                        <ArrowTopRightOnSquareIcon style={{ width: "32px", color: "#222" }} />
                        <p>Conectar URL</p>
                        <label style={{ color: "gray" }}>
                            Conecte URLs da web a seus produtos, como links de download, logins e muito mais, de forma fácil e segura.
                        </label>
                    </Card>
                </Body>
            </Container>
        </Modal>
    )
}

export default ModalSelectActions
