import { put, call, delay } from "redux-saga/effects";
import { POST, GET, PUT } from "../../../../services/api";
import { toast } from "react-toastify";

export function* listSales(action) {
  console.log("listSales action", action)
  try {
    yield put({ type: "SALES_REQUEST" });
    const result = yield call(GET, {
      //endpoint: `/sales_by_user_id?per_page=${action.payload.perPage}`,
      endpoint: `/sales/pagination?per_page=${action.payload.perPage}&page=${action.payload.page}&search=${action.payload?.search}&ini_date=${action.payload?.iniDate}&fin_date=${action.payload?.finDate}`,
    });
    // alert(JSON.stringify(result.data.sales.data));
    yield put({ type: "SALES_SUCCESS", payload: result?.data?.sales });
  } catch (err) {
    //alert(err);
  }
}

export function* reSendWhats(action) {
  yield put({ type: "WHATS_REQUEST" });
  const result = yield call(POST, { endpoint: `/re-send_sak`, data: [action.payload.order], });
  if (result === false) {
    yield put({ type: "WHATS_ERROR" });
  } else {
    yield put({ type: "WHATS_SUCCESS" });
  }
}

export function* reSendEmail(action) {



  yield put({ type: "EMAIL_REQUEST" });
  const result = yield call(POST, { endpoint: `/re-send_email`, data: [action.payload.order], });
  if (result === false) {
    yield put({ type: "EMAIL_ERROR" });
  } else {
    yield put({ type: "EMAIL_SUCCESS" });
  }


  // try {
  //   yield put({ type: "EMAIL_REQUEST" });
  //   const result = yield call(POST, {
  //     endpoint: `/re-send_email`,
  //     data: action.payload,
  //   });

  //   if (result === false) {
  //     yield put({ type: "EMAIL_ERROR" });
  //     toast.error("Email não reenviado");
  //   } else {
  //     yield put({ type: "EMAIL_SUCCESS" });
  //     toast.success("Email reenviado");
  //   }
  // } catch (err) {
  //   alert(err);
  // }
}

export function* reSendEmailMulti(action) {



  yield put({ type: "EMAIL_REQUEST" });
  const result = yield call(POST, { endpoint: `/re-send_email`, data: action.payload.orders, });
  if (result === false) {
    yield put({ type: "EMAIL_ERROR" });
  } else {
    yield put({ type: "EMAIL_SUCCESS" });
  }

}

export function* searchPlatform(action) {

  console.log("saga searchPlatform action", action.payload.order)

  yield put({ type: "SEARCH_PLATFORM_REQUEST" });
  const result = yield call(PUT, { endpoint: `sales/${action.payload.order}`, data: { platform: JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.platform } });
  if (result === false) {
    yield put({ type: "SEARCH_PLATFORM_ERROR" });
  } else {
    yield put({ type: "SEARCH_PLATFORM_SUCCESS", payload: result });
  }

}

export function* searchPlatformAll(action) {

  console.log("saga searchPlatformAll action", action.payload.order)
  //yield delay(1000)
  yield put({ type: "SEARCH_PLATFORM_ALL_REQUEST" });

  //yield delay(3000)

  const result = yield call(GET, { endpoint: `sales/pagination/${action.payload}` });
  if (result === false) {
    yield put({ type: "SEARCH_PLATFORM_ALL_ERROR" });
  } else {
    yield put({ type: "SEARCH_PLATFORM_ALL_SUCCESS", payload: result.data });

    yield put({ type: "SEARCH_PLATFORM_ALL_ITEMS_REQUEST" });

    //yield delay(3000)

    console.log("saga teste result", result)

    let nextPage = null
    for (let index = 0; index < result.data.data.maxPages; index++) {
      yield put({ type: "SEARCH_PLATFORM_ALL_ITEMS_UPDATE", payload: index + 1 });
      const resultItem = yield call(PUT, { endpoint: `sales/pagination/${action.payload}`, data: { page: action.payload == "shopify" && nextPage != null ? nextPage : index + 1, perPage: result.data.data.perPage } });
      nextPage = result.data.nextPage
      //yield delay(3000)
      if (resultItem == false) {
        yield put({ type: "SEARCH_PLATFORM_ALL_ITEMS_ERROR" });
        toast.error("Erro ao tentar atualização.")
        break
      } else {
        //yield put({ type: "SEARCH_PLATFORM_ALL_ITEMS_UPDATE", payload: index + 1 });
        console.log("result item", resultItem)
        if (index + 1 == result.data.data.maxPages) yield put({ type: "SEARCH_PLATFORM_ALL_ITEMS_SUCCESS", payload: result.data.data.maxItems })
      }

    }
    //yield put({ type: "SEARCH_PLATFORM_ALL_ITEMS_SUCCESS", payload: result.data.data.maxItems });
    //yield put({ type: "SEARCH_PLATFORM_ALL_ITEMS_RESET" });

  }

}


export function* updateEmailSales(action) {

  yield put({ type: "UPDATE_EMAIL_SALES_REQUEST" });
  const result = yield call(PUT, { endpoint: `/sales`, data: action.payload });
  if (result === false) {
    yield put({ type: "UPDATE_EMAIL_SALES_ERROR" });
  } else {
    yield put({ type: "UPDATE_EMAIL_SALES_SUCCESS" });
    yield call(listSales, { payload: { perPage: 20, page: 1, search: "", iniDate: "", findate: "" }, })
  }

}