import Modal from "react-modal";
import { BoxActions, BoxUpload, Close, Container, ItemOption, Options, Product, SectionConectar, SectionMedias, SectionUpload, SectionUploadFile, SectionUploadList, SectionVincular, TitleModal } from "./styled";
import { useEffect } from "react";
import Title from "../../../../components/Title";
import { Name, SectionAnexos, SectionImage, SectionInfor } from "../card-product/styled";
import { useState } from "react";

import { AiOutlineCloudUpload } from "react-icons/ai";
import CardUpload from "../card-upload";
import CardCheck from "../card-check";

import { CloseCircle, CloudPlus } from "iconsax-react";
import { useDispatch, useSelector } from "react-redux";
import { size } from "polished";
import Loading from "../../../../components/Loading";
import { BarUpload, StatusFile, UploadFIle, UploadInfor } from "../../../medias/components/card-upload-media/styled";
import { sizeFiles } from "../../../../utils/convert";
import { BoxFiles, BoxFilesList, BoxFilesTitle, BoxForm, BoxUploads, BoxUploadsDrag, BoxUploadsInput } from "../../../medias/components/modal-upload-files/styled";
import { FaCloudUploadAlt } from "react-icons/fa";
import CardUploadMedia from "../../../medias/components/card-upload-media";
import { translations } from "../../../../utils/language";

const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        width: "700px",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        backgroundColor: "white",
        padding: "0px",
        margin: "0px",
        borderRadius: "10px",
    },
    overlay: {
        backgroundColor: "rgba(10,23,55,0.5)",
        zIndex: "99999999"
    },
};



const ModalUploadAnexo = (props) => {

    //const [files, setFiles] = useState([])
    const dispatch = useDispatch()
    const controlMedias = useSelector((state) => state.reducerControlMedias);
    const language = useSelector((state) => state.reducerLanguage);
    const [dragging, setDragging] = useState(false);

    const [dataFiles, setDataFiles] = useState([])

    useEffect(() => {
        console.log("props item", props.item)
    }, [props])


    const setUploadFile = (files) => {
        console.log("fiiles", files)
        setDataFiles([...dataFiles, ...files,])
    }

    const handleDragEnter = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragging(true);
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragging(false);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();

        setDragging(false);
        setDataFiles([...dataFiles, ...e.dataTransfer.files]);
    };

    useEffect(() => {
        if (controlMedias.loading == false && controlMedias.data.length > 0) {
            closeModal()
        }
    }, [controlMedias])

    const closeModal = () => {
        props.setVisible(false)
        //setFiles([])
        setDataFiles([])
        dispatch({ type: "PRODUCT_CONTROL_MEDIA_RESET" })
        props.goSearch()
        //dispatch({ type: "SAGA_LIST_PRODUCTS", payload: { search: "", perPage: 20, page: 1, }, });
    }


    // const addFiles = (values) => {

    //     setFiles([...files, ...values])
    // }

    // const removeFiles = (item) => {
    //     setFiles(files.filter((value) => {
    //         if (value.name == item.name) {
    //             if (value.size != item.size) {
    //                 return value
    //             }
    //         } else {
    //             return value
    //         }
    //     }))
    // }


    // const save = () => {

    //     dispatch({
    //         type: "SAGA_PRODUCT_ADD_MEDIA",
    //         payload: {
    //             product_id: props.item.product_id,
    //             variant_id: props.selectedVariante == "" ? props.selectedVariante : props.selectedVariante.variant.variant_id,
    //             files: files,
    //         },
    //     });
    // }


    return (
        <Modal
            isOpen={props.visible}
            style={customStyles}
            ariaHideApp={false}
            onRequestClose={() => { }}
        >
            <Container>
                <Close onClick={() => { closeModal() }}>
                    <CloseCircle color="gray" size={30} />
                </Close>
                <TitleModal>
                    <Title marginBottom="16px">
                        {translations["products-add-anexo"][language.data]} <span style={{ backgroundColor: "#FFF4CC", color: "#997700", fontSize: "12px", padding: "5px 20px", marginLeft: "10px", borderRadius: '4px' }}>Máximo de 100mb por {translations["medias-arquivo"][language.data]}.</span>
                    </Title>
                </TitleModal>
                <Product>
                    <SectionImage>
                        <img src={props.item.image_url} />
                    </SectionImage>
                    <SectionInfor>
                        <Name>{props.item.name} {props.selectedVariante != "" && `(variante: ${props.selectedVariante.variant.name})`}</Name>
                        <SectionAnexos>
                            {/* {props.getIconFile("pdf", )} */}
                            {props?.medias?.map((item) => {
                                return (
                                    item.type == "url" ?
                                        props.getIconFile("url", item.media) :
                                        props.getIconFile(item.media_name?.split(".")[item.media_name?.split(".").length - 1], item.media_name)
                                )
                            })}
                        </SectionAnexos>
                    </SectionInfor>
                </Product>

                <BoxForm style={{ gap: "20px" }}>
                    <BoxUploads>
                        <BoxUploadsDrag active={dragging}
                            onDragEnter={handleDragEnter}
                            onDragOver={handleDragOver}
                            onDragLeave={handleDragLeave}
                            onDrop={handleDrop}
                        >
                            <FaCloudUploadAlt size={40} color="#12479B" />
                            <label> {translations["medias-arraste-arquivos"][language.data]}</label>
                        </BoxUploadsDrag>
                        <BoxUploadsInput>
                            <label for="upload-file" > {translations["medias-escolher-midias"][language.data]}</label>
                            <input onChange={(e) => { setUploadFile(e.target.files) }} style={{ display: "none" }} id="upload-file" type="file" multiple />
                        </BoxUploadsInput>
                    </BoxUploads>
                    <BoxFiles>
                        <BoxFilesTitle>
                            {dataFiles.length > 0 ? translations["medias-arquivo"][language.data] : translations["medias-nenhum-arquivo"][language.data]}
                        </BoxFilesTitle>
                        <BoxFilesList>
                            {dataFiles.reverse().map((item, index) => {
                                console.log("variant_id", props.selectedVariante)
                                return (
                                    <CardUploadMedia
                                        product_id={props.item.product_id}
                                        variant_id={props?.selectedVariante?.variant?.variant_id ? props?.selectedVariante?.variant?.variant_id : ""}
                                        //variant_id={props?.selectedVariante != "" ? props?.selectedVariante : props?.selectedVariante?.variant?.variant_id}
                                        getIconFile={props.getIconFile}
                                        item={item}
                                        key={index}
                                        position={index} />
                                )
                            })}
                        </BoxFilesList>
                    </BoxFiles>
                </BoxForm>
                {/* <BoxUpload>
                    <SectionMedias>

                        <SectionUploadFile>
                            <label htmlFor={"upload"}>
                                <CloudPlus size={40} color="gray" />
                                <label htmlFor={"upload"} >Selecionar seus arquivos.</label>
                                <input multiple onChange={(e) => { addFiles(e.target.files) }} type="file" name="upload" id="upload" style={{ display: "none" }} />
                            </label>
                        </SectionUploadFile>

                    </SectionMedias>

                    <label style={{fontSize: "14px", color: "gray"}}>
                        {files.length == 0 ?
                            "Nenhum arquivo selecionado" :
                            "Arquivos selecionados"}
                    </label>

                    <SectionUploadList>

                        {files.map((item) => {
                            return <CardUpload removeFiles={removeFiles} loading={false} item={item} getIconFile={props.getIconFile} />
                        })}

                    </SectionUploadList>

                </BoxUpload>
                <BoxActions>
                    {controlMedias.loading ?
                        <label>
                            <Loading size={18} color={"#ffffff"} /> Salvando
                        </label> :
                        <label onClick={() => save()}>
                            Salvar
                        </label>}

                </BoxActions> */}
            </Container>

        </Modal >
    )
}

export default ModalUploadAnexo