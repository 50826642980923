import { Badget, BoxData, BoxPedidos, CheckCustom, Container, ItemAction, SectionActions, SectionDate, SectionInfor } from './styled'
import { useEffect } from 'react'
import { BsCheck } from "react-icons/bs";
import { Refresh2, ExportSquare, GallerySlash, SmsTracking } from "iconsax-react";
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { FiCheckCircle, FiEdit } from 'react-icons/fi';
import ModalEditEmail from '../modal-edit-email';
import { translations } from '../../../../utils/language';
import { FaWhatsapp } from 'react-icons/fa';

const CardPedidos = (props) => {

    const [isCheked, setisCheked] = useState(false)
    const dispatch = useDispatch()

    const [pedidos, setPedidos] = useState(JSON.parse(props.item.order_items))
    const statusSendEmail = useSelector((state) => state.reducerEmails);
    const statusSendWhats = useSelector((state) => state.reducerWhats);
    const language = useSelector((state) => state.reducerLanguage);
    const [visibleModal, setVisibleModal] = useState(false)

    const changeCheck = () => {
        if (isCheked) {
            props.remove(props.item)
            setisCheked(false)
        } else {
            props.add(props.item)
            setisCheked(false)
        }
    }

    useEffect(() => {
        if (props.seletedSales.filter(value => value.id == props.item.id).length > 0) {
            setisCheked(true)
        } else {
            setisCheked(false)
        }
    }, [props.seletedSales])

    const sendEmail = () => {
        dispatch({
            type: "SAGA_RESEND_EMAIL",
            payload: { order: props.item },
        });
    }

    const sendWhats = () => {
        dispatch({
            type: "SAGA_RESEND_WHATS",
            payload: { order: props.item },
        });
    }

    useEffect(() => {
        if (!(statusSendEmail.loading == null && statusSendEmail.error == null && statusSendEmail.data.length == 0)) {
            const id = "statusSendEmail"
            if (statusSendEmail.loading) {
                toast.loading(translations["Aguarde..."][language.data], { toastId: id })
            } else {
                if (statusSendEmail.error) toast.update(id, { render: "Erro ao tentar enviar.", type: "error", isLoading: false, hideProgressBar: false, autoClose: 3000, closeOnClick: true });
                else {
                    toast.update(id, { render: translations["sales-enviado-sucesso"][language.data], type: "success", isLoading: false, hideProgressBar: false, autoClose: 2000, closeOnClick: true })
                }
            }
        }
    }, [statusSendEmail])

    useEffect(() => {
        if (!(statusSendWhats.loading == null && statusSendWhats.error == null && statusSendWhats.data.length == 0)) {
            const id = "statusSendWhats"
            if (statusSendWhats.loading) {
                toast.loading(translations["Aguarde..."][language.data], { toastId: id })
            } else {
                if (statusSendWhats.error) toast.update(id, { render: "Erro ao tentar enviar.", type: "error", isLoading: false, hideProgressBar: false, autoClose: 3000, closeOnClick: true });
                else {
                    toast.update(id, { render: translations["sales-enviado-sucesso"][language.data], type: "success", isLoading: false, hideProgressBar: false, autoClose: 2000, closeOnClick: true })
                }
            }
        }
    }, [statusSendWhats])

    return (
        <div style={{ display: "flex", gap: "10px" }}>
            <ModalEditEmail id={props.item.id} visible={visibleModal} setVisibleModal={setVisibleModal} />
            <CheckCustom onClick={() => { changeCheck() }}>
                {isCheked && <BsCheck size={20} />}
            </CheckCustom>
            <Container>
                <Badget color={{ background: "#E4FFF4", color: "#21D487" }}>
                    <SmsTracking size={15} color={"#21D487"} />
                    <label>E-mail enviado</label>
                </Badget>
                <SectionInfor>
                    <BoxData>
                        <label>
                            {props.item?.customer_name}
                            <span>
                                Pedido: #{props.item.order_number}
                            </span>
                        </label>
                        <p>
                            {props.item.customer_email}
                            <FiEdit onClick={() => { setVisibleModal(true) }} style={{ marginLeft: "5px", cursor: "pointer" }} size={16} />
                        </p>
                    </BoxData>
                    <BoxPedidos>
                        {pedidos?.map((item) => {
                            return (
                                <span>
                                    <img src={item?.product?.image_url} width={40} height={40} />
                                    <label>{item?.product?.name}</label>
                                </span>
                            )
                        })}
                    </BoxPedidos>
                </SectionInfor>

                <SectionDate>
                    {/* {new Date(props.item.created_at).toLocaleDateString('pt-Br', { dateStyle: 'short' })} */}
                    {"" + new Date(props.item.created_at).toLocaleString()}
                </SectionDate>

                <SectionActions>
                    <ItemAction color={"#20D489"} onClick={() => { window.open(props?.item?.order_link, "_blank"); }}  >
                        <ExportSquare size={16} />
                        <label>{translations["sales-acessar"][language.data]}</label>
                    </ItemAction>
                    <ItemAction color={"#F4C17A"} onClick={() => { sendEmail() }}>
                        <Refresh2 size={16} />
                        <label>Reenviar e-mail</label>
                    </ItemAction>

                    {
                        JSON.parse(localStorage.getItem("depoimentos@login"))?.integrationSak != null ?
                            <ItemAction color={"#F4C17A"} onClick={() => { sendWhats() }} >
                                <FaWhatsapp size={18} />
                                <label>Reenviar whatsapp</label>
                            </ItemAction> : null
                    }

                </SectionActions>
            </Container>
        </div>
    )
}

export default CardPedidos