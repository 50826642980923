import { Container, Line, TextLine, SubTitle, ContainerLine, BlockProducts, ContainerSearch, TableMedias, TableMediasHeader, TableMediasCheck, TableMediasSearch, TableMediasActions, SectionCheck, SectionBar, SectionActions, CheckCustom, SectionSearch, SearchCustom, IconFile, ItemFilterSelect, BoxSelect, BoxControl, Upload, } from "./styled";
import Body from "../../../../components/Body";
import SideBar from "../../../../components/SideBar";
import Header from "../../../../components/Header";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Search from "../../../../components/Search";
import { BsCheck, BsLink45Deg, BsFillFileEarmarkFill, BsEmojiSmile, BsFileEarmarkImageFill, BsFillFileEarmarkArrowDownFill, BsFillFileEarmarkPdfFill, BsFillFileEarmarkPlayFill, } from "react-icons/bs";
import Title from "../../../../components/Title";
import { translations } from "../../../../utils/language";
import LoadingLogo from "../../../../components/Loading-logo";
import CardMedia from "../../components/card-media";
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from "react-icons/md";
import { ItemFilter, Paginate, PaginateInfor, PaginateNav } from "../../../products/pages/home-new/styled";
import { DocumentDownload, SearchNormal, SearchNormal1, Trash } from "iconsax-react";
import { toast } from "react-toastify";

import IconUrl from "../../../../assets/imgs/icons/code.svg";
import IconDefault from "../../../../assets/imgs/icons/default.svg"
import IconDoc from "../../../../assets/imgs/icons/doc.svg";
import IconVideo from "../../../../assets/imgs/icons/videoplay.svg";
import IconImage from "../../../../assets/imgs/icons/image.svg";
import IconPdf from "../../../../assets/imgs/icons/pdf.svg";
import AlertCloseBoss from "../../../../components/alert-close-boss";
import ModalInforVideo from "../../../../components/modal-infor-video";
import { FaCloudUploadAlt } from "react-icons/fa";
import ModalUploadFiles from "../../components/modal-upload-files";
import HelpClick from "../../../../components/HelpClick";
import { BiLayout } from "react-icons/bi";
import { ConatainerLoader, ContainerNew } from "../../../dashboard/pages/home-new/styled";
import LayoutNew from "../../../../components/tailwind/layout-new";
const Home = () => {

  const history = useHistory();
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(20);
  const dispatch = useDispatch();
  const medias = useSelector((state) => state.reducerMedias);
  const statusPlatforms = useSelector((state) => state.reducerGetPlatforms);
  const language = useSelector((state) => state.reducerLanguage);
  const [visibleModalInfor, setVisibleModalInfor] = useState(false)
  const [seletedMedias, setSeletedMedias] = useState([])
  const [isChekedAll, setIsChekedAll] = useState(false)
  const statusDeleteMedias = useSelector((state) => state.reducerDeleteMedias);
  const statusUpdateMedias = useSelector((state) => state.reducerUpdateMedias);

  const [visibleModalUploadFiles, setVisibleModalUploadFiles] = useState(false)

  const [typeFilter, setTypeFilter] = useState("")

  const addMedias = (item) => {
    setSeletedMedias([...seletedMedias, item])
  }

  const removeMedias = (item) => {
    setIsChekedAll(false)
    setSeletedMedias(seletedMedias.filter((value) => {
      return item.id != value.id
    }))
  }




  const changeCheck = () => {
    if (isChekedAll) {
      setSeletedMedias([])
      setIsChekedAll(false)
    } else {
      setSeletedMedias(medias.data.data)
      setIsChekedAll(true)
    }
  }



  const countItens = () => {
    let result = 0
    if (page == medias?.data?.meta?.last_page) result = medias?.data?.meta?.total
    else result = page * perPage
    return result
  }

  useEffect(() => {
    dispatch({ type: "SAGA_LIST_MEDIA", payload: { search: search, perPage: perPage, page: page, type: typeFilter }, });
  }, [page, typeFilter])

  const goSearch = () => {
    dispatch({ type: "SAGA_LIST_MEDIA", payload: { search: search, perPage: perPage, page: 1, }, });
  }


  const deleteMedias = () => {
    dispatch({ type: "SAGA_DELETE_MULTI_MEDIA", payload: { medias: seletedMedias } })
    setSeletedMedias([])
    setIsChekedAll(false)
  }

  useEffect(() => {

    if (!(statusDeleteMedias.loading == null && statusDeleteMedias.error == null && statusDeleteMedias.data.length == 0)) {
      const id = "statusDeleteMedias"
      if (statusDeleteMedias.loading) {
        toast.loading("Aguarde...", { toastId: id })
      } else {
        if (statusDeleteMedias.error) toast.update(id, { render: "Erro ao tentar excluir.", type: "error", isLoading: false, hideProgressBar: false, autoClose: 3000, closeOnClick: true });
        else {
          toast.update(id, { render: translations["medias-excluido-sucesso"][language.data], type: "success", isLoading: false, hideProgressBar: false, autoClose: 2000, closeOnClick: true })
          //dispatch({ type: "SAGA_LIST_MEDIA", payload: { search: "", perPage: 20, page: 1, }, });
        }
      }
    }
  }, [statusDeleteMedias])

  useEffect(() => {

    if (!(statusUpdateMedias.loading == null && statusUpdateMedias.error == null && statusUpdateMedias.data.length == 0)) {
      const id = "statusUpdateMedias"
      if (statusUpdateMedias.loading) {
        toast.loading("Aguarde...", { toastId: id })
      } else {
        if (statusUpdateMedias.error) toast.update(id, { render: "Erro ao tentar atualizar.", type: "error", isLoading: false, hideProgressBar: false, autoClose: 3000, closeOnClick: true });
        else {
          toast.update(id, { render: "Atualizado com sucesso.", type: "success", isLoading: false, hideProgressBar: false, autoClose: 2000, closeOnClick: true })
          //dispatch({ type: "SAGA_LIST_MEDIA", payload: { search: "", perPage: 20, page: 1, }, });
        }
      }
    }
  }, [statusUpdateMedias])

  const getIconFile = (type, tooltip) => {
    switch (type?.toLowerCase()) {
      case "png": case "jpg": case "jpeg": case "gif": case "bmp": case "psd": case "svg": case "WEBP":
        return (
          <IconFile>
            {/* <BsFileEarmarkImageFill color={"gray"} size={30} /> */}
            <img src={IconImage} />
            {tooltip?.length > 0 &&
              <label>
                {tooltip}
              </label>}
          </IconFile>
        )
      case "pdf":
        return (
          <IconFile >
            {/* <BsFillFileEarmarkPdfFill color={"gray"} size={30} /> */}
            <img src={IconPdf} />
            {tooltip?.length > 0 &&
              <label>
                {tooltip}
              </label>}
          </IconFile>
        )
      case "mp4": case "avi": case "mkv":
        return (
          <IconFile>
            {/* <BsFillFileEarmarkPlayFill color={"gray"} size={30} /> */}
            <img src={IconVideo} />
            {tooltip?.length > 0 &&
              <label>
                {tooltip}
              </label>}
          </IconFile>
        )
      case "url":
        return (
          <IconFile>
            {/* <BsLink45Deg color={"gray"} size={30} /> */}
            <img src={IconUrl} />
            {tooltip?.length > 0 &&
              <label>
                {tooltip}
              </label>}
          </IconFile>
        )

      default:
        return (
          <IconFile>
            {/* <BsFillFileEarmarkFill color={"gray"} size={30} /> */}
            <img src={IconDefault} />
            {tooltip?.length > 0 &&
              <label>
                {tooltip}
              </label>}
          </IconFile>
        )
    }
  }

  return (
    // <Body>
    //   <SideBar />
    //   <Container>
    //     <Header />

    <LayoutNew>
      <ContainerNew>
        <ModalInforVideo visible={visibleModalInfor} setVisibleModal={setVisibleModalInfor} video={`https://www.youtube.com/embed/${language.data == "es" ? "rVZHrgUcaoc" : "K-QalcWaQ_4"}`} />
        <ModalUploadFiles getIconFile={getIconFile} visible={visibleModalUploadFiles} setVisible={setVisibleModalUploadFiles} />

        {typeof JSON.parse(localStorage.getItem("depoimentos@login"))?.integration == "undefined" || JSON.parse(localStorage.getItem("depoimentos@login"))?.integration == null ?
          <ContainerLine>
            <BlockProducts>
              <Title marginBottom="16px">
                {translations["Adicionar novo arquivo"][language.data]}
              </Title>
              <SubTitle>
                {translations["Confira suas mídias e anexos"][language.data]}
              </SubTitle>
              <Line style={{ display: "flex", alignItems: "center", justifyContent: "center", marginBottom: 12, }} >
                <TextLine>
                  <BsEmojiSmile size={25} style={{ marginRight: "5px" }} />
                  {translations["medias-nao-midias"][language.data]}
                </TextLine>
              </Line>
            </BlockProducts>
          </ContainerLine> :

          medias.loading || medias.loading == null ?
            // <div style={{ height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
            //   <LoadingLogo />
            // </div>
            <ConatainerLoader>
              <LoadingLogo />
            </ConatainerLoader>
            :

            <>
              <ContainerLine>
                <BlockProducts>
                  <TableMedias>
                    <TableMediasHeader>
                      <SectionCheck>
                        <CheckCustom onClick={() => { changeCheck() }}>
                          {isChekedAll && <BsCheck size={20} />}
                        </CheckCustom>
                      </SectionCheck>
                      <SectionBar>
                        <SectionActions style={{ flex: "1" }}>
                          <span onClick={() => { deleteMedias() }}>
                            <Trash size={16} />
                            <label> {translations["medias-deletar-selecionados"][language.data]}</label>
                          </span>
                        </SectionActions>
                        <BoxControl>
                          <BoxSelect>
                            <ItemFilterSelect onChange={(event) => setTypeFilter(event.target.value)}>
                              <option value="pdf" selected={typeFilter == "red" ? true : false}>PDF</option>
                              <option value="png" selected={typeFilter == "png" ? true : false}>PNG</option>
                              <option value="jpeg" selected={typeFilter == "jpeg" ? true : false}>JPEG</option>
                              <option value="" selected={typeFilter == "" ? true : false}>Todos</option>
                            </ItemFilterSelect>
                          </BoxSelect>

                          <SearchCustom>
                            <input onKeyPress={(e) => { e.key == "Enter" && goSearch() }} type="text" placeholder={translations["medias-pesquisar"][language.data]} value={search} onChange={(e) => { setSearch(e.target.value) }} />
                            <span onClick={() => { goSearch() }}>
                              <SearchNormal1 color="#ffffff" size={20} />
                            </span>
                          </SearchCustom>

                          <Upload onClick={() => { setVisibleModalUploadFiles(true) }}>
                            <FaCloudUploadAlt color='#1B76FF' size={14} />
                            <label>{"Upload"}</label>
                          </Upload>

                        </BoxControl>
                      </SectionBar>
                    </TableMediasHeader>
                    {medias?.data?.data?.map((item) => { return (<CardMedia getIconFile={getIconFile} seletedMedias={seletedMedias} add={addMedias} remove={removeMedias} item={item} />) })}
                  </TableMedias>

                  {medias.loading || medias.data.length == 0 ? null :
                    <Paginate>
                      <PaginateNav>
                        <span onClick={() => { if (page != 1) setPage(page - 1) }}>
                          <MdKeyboardArrowLeft size={35} color={medias?.data?.meta?.current_page == 1 ? "gray" : "#19B674"} />
                        </span>
                        <label>{medias?.data?.meta?.current_page}</label>
                        <span onClick={() => { if (page < medias?.data?.meta?.last_page) setPage(page + 1) }}>
                          <MdKeyboardArrowRight size={35} color={medias?.data?.meta?.last_page == page ? "gray" : "#19B674"} />
                        </span>
                      </PaginateNav>
                      <PaginateInfor>
                        <label>Total {countItens()} - {medias.data?.meta?.total}</label>
                      </PaginateInfor>
                    </Paginate>}

                </BlockProducts>
              </ContainerLine>
            </>
        }

        {
          !medias.loading &&
          <div style={{ display: "flex", padding: "20px 0px", justifyContent: "center" }} >
            <div onClick={() => { setVisibleModalInfor(true) }}>
              <HelpClick text={translations["alert-midias"][language.data]} />
            </div>
          </div>
        }
      </ContainerNew>
    </LayoutNew>
  );
};

export default Home;
