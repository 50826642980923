import React, { useEffect, useState } from 'react'
import { ItemAction, ItemActionText, ItemOption, MenuItens, MenuItensText, Name, SectionActions, SectionAnexos, SectionInfor } from './styled'
import { AiOutlinePaperClip, } from "react-icons/ai";
import { translations } from '../../../../utils/language';
import { useSelector } from 'react-redux';
import ModalSelectActions from '../modal-select-action';

const CardVariente = (props) => {

    const [visibleMenu, setVisibleMenu] = useState(false)
    const [visibleModal, setVisibleModal] = useState(false)
    const language = useSelector((state) => state.reducerLanguage);

    const closeMenu = (event) => {
        setVisibleMenu(false);
    };

    useEffect(() => {

        if (visibleMenu) {
            window.addEventListener("mouseup", closeMenu);
            return () => { window.removeEventListener("mouseup", closeMenu); };
        }
    }, [visibleMenu]);

    useEffect(() => {
        console.log("props variante", props)
    }, [props])


    const getStatusActiveIcon = () => {
        if (props.item.variant.active == 1) {
            return true
        } else {
            return false
        }
    }


    return (
        <ItemOption style={{ position: "relative" }}>
            <ModalSelectActions
                setVisibleModalUpload={props.setVisibleModalUpload}
                setVisibleModalVincular={props.setVisibleModalVincular}
                setVisibleModalConectar={props.setVisibleModalConectar}
                visible={visibleModal} setVisible={setVisibleModal}
            />
            <SectionInfor>
                <Name style={{ color: `${props.item.variant.active != 1 && "#d3d3d3"}` }}>{props.item.variant.name} {props.item.variant.active != 1 && " - Excluído"}</Name>
                <SectionAnexos>
                    {props.item.media.map((item) => {
                        return (
                            item.type == "url" ?
                                props.getIconFile("url", item.media, item, getStatusActiveIcon()) :
                                props.getIconFile(item.media_name.split(".")[item.media_name.split(".").length - 1], item.media_name, item, getStatusActiveIcon())
                        )
                    })}
                </SectionAnexos>
            </SectionInfor>
            <SectionActions >

                {/* <ItemAction border={true} onClick={() => { props.setSelectedVariante(props.item); setVisibleMenu(!visibleMenu) }}>
                    <AiOutlinePaperClip size={20} color="#1B76FF" />
                    <label style={{color: "#ffffff"}} >Anexar arquivo</label>
                    <MenuItens visible={visibleMenu}>
                        <p onClick={() => { props.setVisibleModalUpload(true) }}>Upload de arquivo</p>
                        <p onClick={() => { props.setVisibleModalVincular(true) }} >Vincular arquivo existente</p>
                        <p onClick={() => { props.setVisibleModalConectar(true) }}>Conectar URL</p>
                    </MenuItens>
                </ItemAction> */}

                <ItemActionText active={props.item.variant.active == 1 ? true : false} border={true} onClick={() => { props.item.variant.active == 1 && props.setSelectedVariante(props.item); props.item.variant.active == 1 && setVisibleModal(!visibleModal) }}>

                    <AiOutlinePaperClip size={20} color="#ffffff" />
                    <p>{translations[`products-anexar`][language?.data]}</p>


                    <label style={{ color: "#ffffff" }} >{translations[`products-anexar-arquivo`][language?.data]}</label>
                    {/* <MenuItensText visible={visibleMenu}>
                        <p onClick={() => { props.setVisibleModalUpload(true) }}>{translations[`products-upload-arquivos`][language?.data]}</p>
                        <p onClick={() => { props.setVisibleModalVincular(true) }} >{translations[`products-vincular-arquivo`][language?.data]}</p>
                        <p onClick={() => { props.setVisibleModalConectar(true) }}>Conectar URL</p>
                    </MenuItensText> */}
                </ItemActionText>
            </SectionActions>
        </ItemOption>
    )
}

export default CardVariente