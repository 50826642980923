import Modal from "react-modal";
import { BoxActions, BoxUpload, Close, Container, ItemOption, Options, Product, SectionConectar, SectionMedias, SectionUpload, SectionUploadFile, SectionUploadList, SectionVincular, TitleModal } from "./styled";
import { useEffect } from "react";
import Title from "../../../../components/Title";
import { Name, SectionAnexos, SectionImage, SectionInfor } from "../card-product/styled";
import { useState } from "react";

import { AiOutlineCloudUpload } from "react-icons/ai";
import CardUpload from "../card-upload";
import CardCheck from "../card-check";

import { CloseCircle, Add } from "iconsax-react";
import { useDispatch, useSelector } from "react-redux";
import { useStateManager } from "react-select";
import Loading from "../../../../components/Loading";
import { translations } from "../../../../utils/language";

const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        width: "700px",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        backgroundColor: "white",
        padding: "0px",
        margin: "0px",
        borderRadius: "10px",
    },
    overlay: {
        backgroundColor: "rgba(10,23,55,0.5)",
        zIndex: "99999999"
    },
};



const ModalConectarAnexo = (props) => {

    const [files, setFiles] = useState([])
    const dispatch = useDispatch()
    const medias = useSelector((state) => state.reducerMedias);
    const [selectedMedias, setSelectedMedias] = useState([])
    const [vincularSelect, setVincularSelect] = useState(true)
    const controlMedias = useSelector((state) => state.reducerControlMedias);

    const language = useSelector((state) => state.reducerLanguage);

    useEffect(() => {
        if (controlMedias.loading == false && controlMedias.data.length > 0) {
            closeModal()
        }
    }, [controlMedias])

    const closeModal = () => {
        props.setVisible(false)
        setFiles([])
        dispatch({ type: "PRODUCT_CONTROL_MEDIA_RESET" })
    }

    const [urls, setUrls] = useState([])
    const [url, setUrl] = useState("")
    const [urlName, setUrlName] = useState("")


    const addFiles = (values) => {

        setFiles([...files, ...values])
    }

    const addUrls = () => {
        const newUrl = {urlName: urlName, url: url}
        const newDataUrl = urls
        newDataUrl.push(newUrl)
        console.log("url", newDataUrl)
        //setUrls([...urls, newUrl])
        setUrl("")
        setUrlName("")
    }

    useEffect(() => {
        console.log("url", urls)
    }, [urls])


    const removeUrls = (item) => {
        setUrls(urls.filter((value) => {
            return item != value
        }))
    }




    const addSelectedMedias = (value) => {

        setSelectedMedias([...selectedMedias, value])
    }

    const removeSelectMedias = (item) => {
        setSelectedMedias(files.filter((value) => {
            return value.name != item.name
        }))
    }

    const removeFiles = (item) => {
        setFiles(files.filter((value) => {
            return item != value
        }))
    }

    useEffect(() => {

    }, [medias])


    const save = () => {
        dispatch({
            type: "SAGA_PRODUCT_URL_MEDIA",
            payload: {
                product_id: props.item.product_id,
                variant_id: props.selectedVariante == "" ? props.selectedVariante : props.selectedVariante.variant.variant_id,
                urls: urls,
            },
        });
    }

    useEffect(() => {

    }, [props])


    return (
        <Modal
            isOpen={props.visible}
            style={customStyles}
            ariaHideApp={false}
            onRequestClose={() => { }}
        >
            <Container>
                <Close onClick={() => { props.setVisible(false) }}>
                    <CloseCircle color="gray" size={30} />
                </Close>
                <TitleModal>
                    <Title marginBottom="16px">
                        {translations[`products-add-anexo`][language?.data]}
                    </Title>
                </TitleModal>
                <Product>
                    <SectionImage>
                        <img src={props.item.image_url} />
                    </SectionImage>
                    <SectionInfor>
                        <Name>{props.item.name} {props.selectedVariante != "" && `(variante: ${props.selectedVariante.variant.name})`}</Name>
                        <SectionAnexos>
                            {props.medias?.map((item) => {
                                return (
                                    item.type == "url" ?
                                        props.getIconFile("url", item.media) :
                                        props.getIconFile(item.media_name.split(".")[item.media_name.split(".").length - 1], item.media_name)
                                )
                            })}
                        </SectionAnexos>
                    </SectionInfor>
                </Product>
                <BoxUpload>
                    <SectionMedias>
                        <label style={{ display: "flex", padding: "20px 0px", justifyContent: "center", fontSize: "12px", textAlign: "center", color: "#1b76ff", backgroundColor: "#1b76ff11" }}>
                            {translations[`products-digite-url`][language?.data]}
                        </label>
                        <SectionConectar>
                            <input style={{ marginRight: "20px" }} type="text" placeholder="Nome para URL" value={urlName} onChange={(e) => { setUrlName(e.target.value) }} />
                            <input value={url} type="text" placeholder="https://www.suaurl.com.br" onChange={(e) => { setUrl(e.target.value) }} />
                            {/* <label onClick={() => addUrls()}>Adicionar</label> */}
                            <span onClick={() => url.length > 0 && addUrls()}>
                                <Add size={30} color="#ffffff" />
                            </span>
                        </SectionConectar>
                    </SectionMedias>

                    <label style={{ fontSize: "14px", color: "gray" }}>
                        {urls.length == 0 ?
                            translations[`products-nenhuma-url`][language?.data] :
                            translations[`products-url-add`][language?.data]}
                    </label>

                    <SectionUploadList>
                        {urls.map((item) => {
                            return <CardUpload type={"conectar"} removeFiles={removeUrls} loading={false} item={item} getIconFile={props.getIconFile} />
                        })}
                    </SectionUploadList>

                </BoxUpload>
                <BoxActions>
                    {controlMedias.loading ?
                        <label>
                            <Loading size={18} color={"#ffffff"} /> Salvando
                        </label> :
                        <label onClick={() => save()}>
                            {translations[`Salvar`][language?.data]}
                        </label>}
                </BoxActions>
            </Container>

        </Modal >
    )
}

export default ModalConectarAnexo