import { Container, Btn } from "./styled";
import { useState } from "react";
import { useEffect } from "react";
import { AiFillCheckCircle } from "react-icons/ai";
import ModalInfor from "../Modal-Integration";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import ModalConfirmation from "../../../../components/Modal-Confirmation";
import { BsTools } from "react-icons/bs";
import { translations } from "../../../../utils/language";

const Card = (props) => {
  const [visibleModalInfor, setVisibleModalInfor] = useState(false);
  const statusDeleteIntegration = useSelector((state) => state.reducerDeleteIntegration);
  const [visibleModalConfirmation, setVisibleModalConfirmation] = useState(false);
  const language = useSelector((state) => state.reducerLanguage);
  const dispatch = useDispatch();
  const statusPlatforms = useSelector(state => state.reducerGetPlatforms)

  useEffect(() => {
    console.log("props card", props, language.data)
  }, [props])


  useEffect(() => {
    if (!statusDeleteIntegration.loading && !statusDeleteIntegration.error && statusDeleteIntegration.data.length === 0) {
    } else {
      const id = "statusDeleteIntegration";
      if (statusDeleteIntegration.loading) {
        toast.loading("Aguarde...", { toastId: id });
      } else {
        if (statusDeleteIntegration.error) {
          toast.update(id, { render: "Erro ao tentar excluir.", type: "error", isLoading: false, hideProgressBar: false, autoClose: 3000, closeOnClick: true, });
        } else {
          toast.update(id, { render: "Excluído com sucesso.", type: "success", isLoading: false, hideProgressBar: false, autoClose: 2000, closeOnClick: true, });
          setVisibleModalConfirmation(false);
        }
      }
    }
  }, [statusDeleteIntegration]);

  const deleteIntegration = () => {
    dispatch({ type: "SAGA_DELETE_NEW_INTEGRATION", payload: { platform: props.platform.title.toLowerCase(), }, });
  };

  const getInfor = (platform) => {

    switch (platform) {
      case "Dropi":
        return `Chave Emprender: ${JSON.parse(localStorage.getItem("depoimentos@login"))?.integrationDropi?.user_token}`;
      case "Shopify":
        return `Loja: ${JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.url}`;
      // case "Nuvemshop":
      //   return `Loja: ${
      //     JSON.parse(localStorage.getItem("depoimentos@login"))?.integration
      //       ?.url
      //   }`;
      case "Custom":
        return `Site: ${JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.url}`;
      case "Montink":
        return `Loja: ${JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.url}`;
      case "Hotmart":
        return `Loja: ${JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.url}`;
      case "Sak":
        return props.install ? `Token: ${statusPlatforms?.data[1].access_token.substr(0, 5)} *************** ${statusPlatforms?.data[1].access_token?.substr(statusPlatforms?.data[1]?.access_token?.length - 5, statusPlatforms?.data[1]?.access_token?.length - 1)}` : null
      default:
        return null
    }
  };



  const validate = () => {

    if (props.platform.title == "Sak" || props.platform.title == "Rastreio") {
      if (JSON.parse(localStorage.getItem("depoimentos@login"))?.integration) {
        //alert("Fazer integração")
        !props.install ? setVisibleModalInfor(true) : setVisibleModalConfirmation(true)
      } else if (props.install) {
        setVisibleModalConfirmation(true)
      } else {
        //alert("Por favor integrar uma plataforma primeiro")
        toast.info(translations["integrations-integre-plataforma"][language?.data], { autoClose: false, })

      }
    } else if (JSON.parse(localStorage.getItem("depoimentos@login"))?.integration) {

      if (props.platform.title.toLowerCase().replace(" ", "") == JSON.parse(localStorage.getItem("depoimentos@login"))?.integration.platform.toLowerCase()) {
        setVisibleModalConfirmation(true)
      } else {
        //toast.info("já existe uma integração", { autoClose: false, })
        toast.info(translations["integrations-existe-integracao"][language?.data], { autoClose: false, })
      }
    } else {
      setVisibleModalInfor(true)
    }
  }

  const getConfirmation = () => {
    if (props?.platform?.title == "Sak") {
      return "Deseja excluir essa integração com Sak"
    } else {
      return translations[`Deseja excluir essa integração com ${props?.platform?.title} ?`][language?.data]
    }
  }


  const getTitle = () => {
    if (props?.platform?.title == "Sak") {
      return "Sak"
    } else if (props?.platform?.title == "Rastreio") {
      return "Rastreio."
    } else {
      return translations[props.platform?.title][language.data]
    }
  }

  const getMessage = () => {
    if (props?.platform?.title == "Sak") {
      return "Integração SAK."
    } else if (props?.platform?.title == "Rastreio") {
      return "Integração Rastreio."
    } else {
      return translations[props.platform?.message][language.data]
    }
  }


  return (
    <>
      <ModalInfor platform={props.platform} visible={visibleModalInfor} setVisibleModal={setVisibleModalInfor} item={props.item} />
      <ModalConfirmation
        confirmation={deleteIntegration}
        visible={visibleModalConfirmation}
        setVisibleModal={setVisibleModalConfirmation}
        title={getConfirmation()}
        //title={translations[`Deseja excluir essa integração com ${props?.platform?.title} ?`][language?.data]}
        infor={getInfor(props.platform.title)}
      />

      <Container titleColor={props.platform?.titleColor} onClick={() => { validate() }}      >
        <Btn >
          {props.install ?
            <span>
              <AiFillCheckCircle size={25} color={"green"} />
            </span> :
            <label>Instalar</label>
          }
        </Btn>
        <div >
          <img style={{height: "38px"}} src={props.platform?.image} height={38} alt="image_platform" />
          <label>{getMessage()}</label>
        </div>
        <span  >
          {/* <label>{"translations[props.platform?.title][language.data]"}</label> */}
          <label>{getTitle()}</label>
        </span>
      </Container >
    </>
  );
};

export default Card;
