import { Container, ContainerLine, BlockProducts, Line, TextLine, ButtonSeeAll, TitleButton, SubTitle, ContainerSearch, Paginate, PaginateNav, PaginateInfor, ItemFilter, OptionsUpdate, MenuOption, } from "./styled";
import Body from "../../../../components/Body";
import SideBar from "../../../../components/SideBar";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Header from "../../../../components/Header";
import Title from "../../../../components/Title";
import { BsEmojiSmile, BsHash } from "react-icons/bs";
import Search from "../../../../components/Search";
import { translations } from "../../../../utils/language";
import LoadingLogo from "../../../../components/Loading-logo";
import TableProducts from "../../components/table-products";
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from "react-icons/md";
import { BoxSelect, ItemFilterSelect, SearchCustom, SectionBar, TableMediasHeader } from "../../../medias/pages/home-new/styled";
import { SearchNormal1 } from "iconsax-react";
import AlertCloseBoss from "../../../../components/alert-close-boss";
import ModalInforVideo from "../../../../components/modal-infor-video";
import { IoMdArrowDropdown } from "react-icons/io";
import { RxUpdate } from "react-icons/rx";
import IconProducts from "../../../../assets/imgs/icon-produtos.svg";
import ModalSearchAllPlatformProdducts from "../../components/modal-search-all-platform-products";
import HelpClick from "../../../../components/HelpClick";
import LayoutNew from "../../../../components/tailwind/layout-new";
import { ConatainerLoader, ContainerNew } from "../../../dashboard/pages/home-new/styled";

const Home = () => {
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(20);
  const [isAll, setIsAll] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const products = useSelector((state) => state.reducerProducts);
  const statusPlatforms = useSelector((state) => state.reducerGetPlatforms);
  const language = useSelector((state) => state.reducerLanguage);
  const [visibleModalInfor, setVisibleModalInfor] = useState(false)

  const [mediasFilter, setMediasFilter] = useState("all")

  const [visibleModalSearchAllPlatform, setVisibleModalSearchAllPlatform] = useState(false)

  const [visibleMenuUpdate, setVisibleMenuUpdate] = useState(false)

  // useEffect(() => {
  //   if (perPage == 4 && search.length > 0) {
  //     setPerPage(products?.data?.total);
  //     setIsAll(true);
  //   }
  //   dispatch({ type: "SAGA_LIST_PRODUCTS", payload: { search: search, perPage: perPage, page: page, }, });
  // }, [dispatch, perPage, page]);

  // useEffect(() => {
  //   dispatch({ type: "SAGA_GET_PLATFORMS_INTEGRATION" });
  // }, [dispatch]);

  useEffect(() => {
    //dispatch({ type: "SAGA_LIST_PRODUCTS", payload: { search: search, perPage: perPage, page: page, }, });
    //dispatch({ type: "SAGA_GET_PLATFORMS_INTEGRATION" });

  }, [])



  const countItens = () => {
    let result = 0
    if (page == products?.data?.data?.meta?.last_page) result = products?.data?.data?.meta?.total
    else result = page * perPage
    return result
  }

  useEffect(() => {
    dispatch({ type: "SAGA_LIST_PRODUCTS", payload: { search: search, perPage: perPage, page: page, media: mediasFilter }, });

  }, [page, mediasFilter])


  const goSearch = () => {

    dispatch({ type: "SAGA_LIST_PRODUCTS", payload: { search: search, perPage: perPage, page: page, }, });
  }


  const closeMenu = (event) => { setVisibleMenuUpdate(false); };

  useEffect(() => {

    if (visibleMenuUpdate) {
      window.addEventListener("mouseup", closeMenu);
      return () => {
        window.removeEventListener("mouseup", closeMenu);
      };
    }
  }, [visibleMenuUpdate]);

  return (
    <LayoutNew>

      <ModalInforVideo visible={visibleModalInfor} setVisibleModal={setVisibleModalInfor} video={`https://www.youtube.com/embed/${language.data == "es" ? "7rtzDd297N8" : "G4ewQpXuEEI"}`} />
      <ModalSearchAllPlatformProdducts goSearch={goSearch} visible={visibleModalSearchAllPlatform} setVisibleModal={setVisibleModalSearchAllPlatform} />

      <ContainerNew>
        {typeof JSON.parse(localStorage.getItem("depoimentos@login"))?.integration == "undefined" || JSON.parse(localStorage.getItem("depoimentos@login"))?.integration == null ?
          <ContainerLine>
            <BlockProducts>
              <Title marginBottom="16px">
                {translations["Adicionar novo arquivo"][language.data]}
              </Title>
              <SubTitle>
                {translations["Selecione produtos e vincule arquivos digitais para começar a vender"][language.data]}
              </SubTitle>
              <Line style={{ display: "flex", alignItems: "center", justifyContent: "center", marginBottom: 12, }} >
                <TextLine>
                  <BsEmojiSmile size={25} style={{ marginRight: "5px" }} />
                  {translations["Você ainda não integrou com nenhuma plataforma"][language.data]},{" "}
                  <a onClick={() => history.push("/integracoes")} style={{ color: "#1B76FF", cursor: "pointer" }}>
                    clique aqui
                  </a>{" "}
                  para integrar
                </TextLine>
              </Line>
            </BlockProducts>
          </ContainerLine> :

          products.loading || products.data.length == 0 ?
            // <div style={{ height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
            //   <LoadingLogo />
            // </div>
            <ConatainerLoader>
              <LoadingLogo />
            </ConatainerLoader>
            :

            <>
              <ContainerLine>
                <BlockProducts>
                  <Title marginBottom="16px">
                    {translations["Produtos"][language.data]}
                  </Title>
                  <ContainerSearch>
                    <SubTitle>
                      {translations["Selecione produtos e vincule arquivos digitais para começar a vender"][language.data]}
                    </SubTitle>
                    {/* <Search search={search} setSearch={setSearch} /> */}
                  </ContainerSearch>
                  <>
                    <TableMediasHeader>
                      <SectionBar style={{ justifyContent: "end", marginLeft: "0px" }}>

                        <BoxSelect>
                          <ItemFilterSelect onChange={(event) => setMediasFilter(event.target.value)}>
                            <option value="only" selected={mediasFilter == "only" ? true : false}>  {translations["products-com-midias"][language.data]}</option>
                            <option value="not" selected={mediasFilter == "not" ? true : false}>{translations["products-sem-midias"][language.data]}</option>
                            <option value="all" selected={mediasFilter == "all" || mediasFilter == "" ? true : false}>Todos</option>
                          </ItemFilterSelect>
                        </BoxSelect>

                        <SearchCustom>
                          <input onKeyPress={(e) => { e.key == "Enter" && goSearch() }} type="text" placeholder="Buscar" value={search} onChange={(e) => { setSearch(e.target.value) }} />
                          <span onClick={() => { goSearch() }}>
                            <SearchNormal1 color="#ffffff" size={20} />
                          </span>
                        </SearchCustom>

                        <OptionsUpdate onClick={() => { setVisibleMenuUpdate(!visibleMenuUpdate) }}>
                          <RxUpdate color='#222' size={14} />
                          <label>{translations["sales-atualizar"][language.data]}</label>
                          <IoMdArrowDropdown />
                          <MenuOption visible={visibleMenuUpdate}>
                            <label
                              onClick={() => {
                                dispatch({ type: "SAGA_SEARCH_PLATFORM_ALL_PRODUCTS", payload: JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.platform }); closeMenu()
                              }}
                            >
                              <img src={IconProducts} />
                              {translations["products-todos-produtos"][language.data]}
                            </label>
                          </MenuOption>
                        </OptionsUpdate>

                      </SectionBar>

                    </TableMediasHeader>
                    <TableProducts products={products?.data?.data?.data} search={search} goSearch={goSearch} />
                  </>


                  {products.loading || products.data.length == 0 ? null :
                    <Paginate>
                      <PaginateNav>
                        <span onClick={() => { if (page != 1) setPage(page - 1) }}>
                          <MdKeyboardArrowLeft size={35} color={products?.data?.data?.meta?.current_page == 1 ? "gray" : "#19B674"} />
                        </span>
                        <label>{products?.data?.data?.meta?.current_page}</label>
                        <span onClick={() => { if (page < products?.data?.data?.meta?.last_page) setPage(page + 1) }}>
                          <MdKeyboardArrowRight size={35} color={products?.data?.data?.meta?.last_page == page ? "gray" : "#19B674"} />
                        </span>
                      </PaginateNav>
                      <PaginateInfor>
                        <label>Total {countItens()} - {products.data.data?.meta?.total}</label>
                      </PaginateInfor>
                    </Paginate>}

                </BlockProducts>
              </ContainerLine>
            </>
        }

        {
          !products.loading &&
          <div style={{ display: "flex", padding: "20px 0px", justifyContent: "center" }} >
            <div onClick={() => { setVisibleModalInfor(true) }}>
              <HelpClick text={translations["alert-produtos"][language.data]} />
            </div>
          </div>
        }
      </ContainerNew>
    </LayoutNew>
    // <Body>
    //   <SideBar />
    //   <Container>
    //     <Header />

    //     <ModalInforVideo visible={visibleModalInfor} setVisibleModal={setVisibleModalInfor} video={`https://www.youtube.com/embed/${language.data == "es" ? "7rtzDd297N8" : "G4ewQpXuEEI"}`} />
    //     <ModalSearchAllPlatformProdducts goSearch={goSearch} visible={visibleModalSearchAllPlatform} setVisibleModal={setVisibleModalSearchAllPlatform} />

    //     {typeof JSON.parse(localStorage.getItem("depoimentos@login"))?.integration == "undefined" || JSON.parse(localStorage.getItem("depoimentos@login"))?.integration == null ?
    //       <ContainerLine>
    //         <BlockProducts>
    //           <Title marginBottom="16px">
    //             {translations["Adicionar novo arquivo"][language.data]}
    //           </Title>
    //           <SubTitle>
    //             {translations["Selecione produtos e vincule arquivos digitais para começar a vender"][language.data]}
    //           </SubTitle>
    //           <Line style={{ display: "flex", alignItems: "center", justifyContent: "center", marginBottom: 12, }} >
    //             <TextLine>
    //               <BsEmojiSmile size={25} style={{ marginRight: "5px" }} />
    //               {translations["Você ainda não integrou com nenhuma plataforma"][language.data]},{" "}
    //               <a onClick={() => history.push("/integracoes")} style={{ color: "#1B76FF", cursor: "pointer" }}>
    //                 clique aqui
    //               </a>{" "}
    //               para integrar
    //             </TextLine>
    //           </Line>
    //         </BlockProducts>
    //       </ContainerLine> :

    //       products.loading || products.data.length == 0 ?
    //         <div style={{ height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
    //           <LoadingLogo />
    //         </div> :

    //         <>
    //           <ContainerLine>
    //             <BlockProducts>
    //               <Title marginBottom="16px">
    //                 {translations["Produtos"][language.data]}
    //               </Title>
    //               <ContainerSearch>
    //                 <SubTitle>
    //                   {translations["Selecione produtos e vincule arquivos digitais para começar a vender"][language.data]}
    //                 </SubTitle>
    //                 {/* <Search search={search} setSearch={setSearch} /> */}
    //               </ContainerSearch>
    //               <>
    //                 <TableMediasHeader>
    //                   <SectionBar style={{ justifyContent: "end", marginLeft: "0px" }}>

    //                     <BoxSelect>
    //                       <ItemFilterSelect onChange={(event) => setMediasFilter(event.target.value)}>
    //                         <option value="only" selected={mediasFilter == "only" ? true : false}>  {translations["products-com-midias"][language.data]}</option>
    //                         <option value="not" selected={mediasFilter == "not" ? true : false}>{translations["products-sem-midias"][language.data]}</option>
    //                         <option value="all" selected={mediasFilter == "all" || mediasFilter == "" ? true : false}>Todos</option>
    //                       </ItemFilterSelect>
    //                     </BoxSelect>

    //                     <SearchCustom>
    //                       <input onKeyPress={(e)=>{e.key == "Enter" && goSearch()}} type="text" placeholder="Buscar" value={search} onChange={(e) => { setSearch(e.target.value) }} />
    //                       <span onClick={() => { goSearch() }}>
    //                         <SearchNormal1 color="#ffffff" size={20} />
    //                       </span>
    //                     </SearchCustom>

    //                     <OptionsUpdate onClick={() => { setVisibleMenuUpdate(!visibleMenuUpdate) }}>
    //                       <RxUpdate color='#222' size={14} />
    //                       <label>{translations["sales-atualizar"][language.data]}</label>
    //                       <IoMdArrowDropdown />
    //                       <MenuOption visible={visibleMenuUpdate}>
    //                         <label
    //                           onClick={() => {
    //                             dispatch({ type: "SAGA_SEARCH_PLATFORM_ALL_PRODUCTS", payload: JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.platform }); closeMenu()
    //                           }}
    //                         >
    //                           <img src={IconProducts} />
    //                           {translations["products-todos-produtos"][language.data]}
    //                         </label>
    //                       </MenuOption>
    //                     </OptionsUpdate>

    //                   </SectionBar>

    //                 </TableMediasHeader>
    //                 <TableProducts products={products?.data?.data?.data} search={search} goSearch={goSearch} />
    //               </>


    //               {products.loading || products.data.length == 0 ? null :
    //                 <Paginate>
    //                   <PaginateNav>
    //                     <span onClick={() => { if (page != 1) setPage(page - 1) }}>
    //                       <MdKeyboardArrowLeft size={35} color={products?.data?.data?.meta?.current_page == 1 ? "gray" : "#19B674"} />
    //                     </span>
    //                     <label>{products?.data?.data?.meta?.current_page}</label>
    //                     <span onClick={() => { if (page < products?.data?.data?.meta?.last_page) setPage(page + 1) }}>
    //                       <MdKeyboardArrowRight size={35} color={products?.data?.data?.meta?.last_page == page ? "gray" : "#19B674"} />
    //                     </span>
    //                   </PaginateNav>
    //                   <PaginateInfor>
    //                     <label>Total {countItens()} - {products.data.data?.meta?.total}</label>
    //                   </PaginateInfor>
    //                 </Paginate>}

    //             </BlockProducts>
    //           </ContainerLine>
    //         </>
    //     }

    //     {
    //       !products.loading &&
    //       <div style={{ display: "flex", padding: "20px 0px", justifyContent: "center" }} >
    //         <div onClick={() => { setVisibleModalInfor(true) }}>
    //           <HelpClick text={translations["alert-produtos"][language.data]} />
    //         </div>
    //       </div>
    //     }
    //   </Container>
    // </Body >
  );
};

export default Home;