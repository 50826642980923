import React, { useEffect, useState } from 'react'
import { ContainerMain } from './styled'
import CardProduct from '../card-product'
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
// import ModalUploadAnexo from '../modal-upload-anexo'
// import ModalConectarAnexo from '../modal-conectar-anexo'
// import ModalVincularAnexo from '../modal-vincular-anexo'
// import { BoxOptions, BoxProduct, Container, IconFile, ItemAction, MenuItens, Name, SectionActions, SectionAnexos, SectionImage, SectionInfor } from '../card-product/styled'
// import ModalConfirmation from '../../../../components/Modal-Confirmation'
// import { FiCameraOff } from 'react-icons/fi'
// import { AiOutlinePaperClip, AiOutlineReload } from 'react-icons/ai'
// import { IoIosArrowDown, IoIosArrowForward } from 'react-icons/io'
// import CardVariente from '../card-variante'


// import IconUrl from "../../../../assets/imgs/icons/code.svg";
// import IconDefault from "../../../../assets/imgs/icons/default.svg"
// import IconVideo from "../../../../assets/imgs/icons/videoplay.svg";
// import IconImage from "../../../../assets/imgs/icons/image.svg";
// import IconPdf from "../../../../assets/imgs/icons/pdf.svg";
// import { useDispatch } from 'react-redux'

const TableProducts = (props) => {

    const reducerControlCloseModal = useSelector((state) => state.reducerControlCloseModal);
    const reducerControlCloseModalLink = useSelector((state) => state.reducerControlCloseModalLink);
    const reducerControlCloseModalUrl = useSelector((state) => state.reducerControlCloseModalUrl);

    const reducerControlRemoveMedia = useSelector((state) => state.reducerControlRemoveMedia);
    const dispatch = useDispatch()

    useEffect(() => {
        console.log("props", props)
    }, [])


    useEffect(() => {
        console.log("reducerControlCloseModal teste", reducerControlCloseModal)
        if (reducerControlCloseModal.status == null) {
            // dispatch({
            //     type: "SAGA_LIST_PRODUCTS",
            //     payload: {
            //         search: props.search,
            //         perPage: 20,
            //         page: 1,
            //         log: "closemodal modal-upload-anexo"
            //     },
            // });
            props.goSearch()
        }
    }, [reducerControlCloseModal,])

    useEffect(() => {
        console.log("reducerControlCloseModal teste", reducerControlRemoveMedia)
        if (reducerControlRemoveMedia.status == null) {
            props.goSearch()
        }
    }, [reducerControlRemoveMedia,])

    useEffect(() => {
        console.log("reducerControlCloseModal teste", reducerControlCloseModalLink)
        if (reducerControlCloseModalLink.status == null) {
            // dispatch({
            //     type: "SAGA_LIST_PRODUCTS",
            //     payload: {
            //         search: props.search,
            //         perPage: 20,
            //         page: 1,
            //         log: "closemodal modal-link-anexo"
            //     },
            // });
            props.goSearch()
        }
    }, [reducerControlCloseModalLink])

    useEffect(() => {
        console.log("reducerControlCloseModalUrl teste", reducerControlCloseModalUrl)
        if (reducerControlCloseModalUrl.status == null) {
            // dispatch({
            //     type: "SAGA_LIST_PRODUCTS",
            //     payload: {
            //         search: props.search,
            //         perPage: 20,
            //         page: 1,
            //         log: "closemodal modal-url-anexo"
            //     },
            // });
            props.goSearch()
        }
    }, [reducerControlCloseModalUrl])


    // const [visibleModalUpload, setVisibleModalUpload] = useState(false)
    // const [visibleModalVincular, setVisibleModalVincular] = useState(false)
    // const [visibleModalConectar, setVisibleModalConectar] = useState(false)

    // const [visibleMenu, setVisibleMenu] = useState(false)
    // const [visibleModalConfirmation, setVisibleModalConfirmation] = useState(false)

    // const dispatch = useDispatch()

    // const [selectedVariante, setSelectedVariante] = useState("")
    // const [deleteMedia, setDeleteMedia] = useState(null)

    // const [visibleOptions, setvisibleOptions] = useState(false)

    // const deleteMediaProduct = () => {
    //     dispatch({
    //         type: "DELETE_LINK_MEDIA_PRODUCTS",
    //         payload: {
    //             media_id: deleteMedia.id,
    //             product_id: props.item.products.id,
    //         },
    //     });
    // }

    // const updateProduct = () => {
    //     dispatch({
    //          type: "SAGA_UPDATE_DATA_PRODUCT",
    //          payload: {
    //              integration: JSON.parse(localStorage.getItem("depoimentos@login"))?.integration,
    //              product: props.item.products,

    //          }
    //      })
    //  }

    // const cliqueCard = (sync = true) => {

    //     if (sync) {
    //         setvisibleOptions(!visibleOptions);
    //     } else {
    //         updateProduct();
    //     }
    // }

    // const clickMedia = (itemMedia) => {

    //     setDeleteMedia(itemMedia)
    //     setVisibleModalConfirmation(true)
    // }

    // const getIconFile = (type, tooltip, media) => {
    //     switch (type?.toLowerCase()) {
    //         case "png": case "jpg": case "jpeg": case "gif": case "bmp": case "psd": case "svg": case "WEBP":
    //             return (
    //                 <IconFile onClick={() => { tooltip?.length > 0 && clickMedia(media) }}>
    //                     {/* <BsFileEarmarkImageFill color={iconFile.color} size={iconFile.size} /> */}
    //                     <img src={IconImage} />
    //                     {tooltip?.length > 0 &&
    //                         <label>
    //                             {tooltip}
    //                         </label>}
    //                 </IconFile>
    //             )
    //         case "pdf":
    //             return (
    //                 <IconFile onClick={() => { tooltip?.length > 0 && clickMedia(media) }} >
    //                     {/* <BsFileEarmarkPdfFill color={iconFile.color} size={iconFile.size} /> */}
    //                     <img src={IconPdf} />
    //                     {tooltip?.length > 0 &&
    //                         <label>
    //                             {tooltip}
    //                         </label>}
    //                 </IconFile>
    //             )
    //         case "mp4": case "avi": case "mkv":
    //             return (
    //                 <IconFile onClick={() => { tooltip?.length > 0 && clickMedia(media) }}>
    //                     {/* <BsFillFileEarmarkPlayFill color={iconFile.color} size={iconFile.size} /> */}
    //                     <img src={IconVideo} />
    //                     {tooltip?.length > 0 &&
    //                         <label>
    //                             {tooltip}
    //                         </label>}
    //                 </IconFile>
    //             )
    //         case "url":
    //             return (
    //                 <IconFile onClick={() => { tooltip?.length > 0 && clickMedia(media) }}>
    //                     {/* <BsLink45Deg color={iconFile.color} size={iconFile.size} /> */}
    //                     <img src={IconUrl} />
    //                     {tooltip?.length > 0 &&
    //                         <label>
    //                             {tooltip}
    //                         </label>}
    //                 </IconFile>
    //             )

    //         default:
    //             return (
    //                 <IconFile onClick={() => { tooltip?.length > 0 && clickMedia(media) }}>
    //                     {/* <BsFillFileEarmarkFill color={iconFile.color} size={iconFile.size} /> */}
    //                     <img src={IconDefault} />
    //                     {tooltip?.length > 0 &&
    //                         <label>
    //                             {tooltip}
    //                         </label>}
    //                 </IconFile>
    //             )
    //     }
    // }


    return (
        <ContainerMain>

            {/* <ModalUploadAnexo search={props.search} medias={props.item.media} getIconFile={getIconFile} item={props.item.products} selectedVariante={selectedVariante} visible={visibleModalUpload} setVisible={setVisibleModalUpload} />
            <ModalConectarAnexo search={props.search} medias={props.item.media} getIconFile={getIconFile} item={props.item.products} selectedVariante={selectedVariante} visible={visibleModalConectar} setVisible={setVisibleModalConectar} />
            <ModalVincularAnexo search={props.search} medias={props.item.media} getIconFile={getIconFile} item={props.item.products} selectedVariante={selectedVariante} visible={visibleModalVincular} setVisible={setVisibleModalVincular} /> */}

            {props?.products?.map((item) => { return <CardProduct goSearch={props.goSearch} search={props.search} item={item} /> })}
            {/* {props?.products?.map((item) => {
                return (
                    <Container >
                        <ModalConfirmation
                            confirmation={deleteMediaProduct}
                            setVisibleModal={setVisibleModalConfirmation}
                            visible={visibleModalConfirmation}
                            title={`Deseja desvincular a mídia ${deleteMedia?.type == "url" ? deleteMedia?.media : deleteMedia?.media_name} do produto ${props.item.products?.name} ?`}
                        />
                        <BoxProduct >

                            <SectionImage onClick={() => cliqueCard()}>
                                {props.item.products.image_url?.length > 0 ?
                                    < img src={props.item.products.image_url} /> :
                                    <FiCameraOff size={40} color='gray' />}

                            </SectionImage>
                            <SectionInfor onClick={() => cliqueCard()}>
                                <Name>{props.item.products?.name}</Name>
                                <SectionAnexos>
                                    {props.item?.media?.map((item) => {
                                        return (
                                            item?.type == "url" ?
                                                getIconFile("url", item?.media, item) :
                                                getIconFile(item?.media_name?.split(".")[item?.media_name?.split(".").length - 1], item?.media_name, item)
                                        )
                                    })}
                                </SectionAnexos>
                            </SectionInfor>
                            <SectionActions >
                                <ItemAction
                                    //style={{ display: props.item?.variants?.length == 0 ? "flex" : "none" }}
                                    border={true}
                                    onClick={() => { cliqueCard(false) }} >
                                    <AiOutlineReload size={20} color="#1B76FF" />
                                    <label>Sincronizar dados desse produto com sua plataforma.</label>
                                </ItemAction>

                                <ItemAction
                                    style={{ position: "relative", display: props.item?.variants?.length == 0 ? "flex" : "none" }}
                                    border={true} onClick={() => { setVisibleMenu(!visibleMenu) }} >
                                    <AiOutlinePaperClip size={20} color="#1B76FF" />
                                    <label>Anexar arquivo</label>

                                    <MenuItens visible={visibleMenu}>
                                        <p onClick={() => { setVisibleModalUpload(true) }}>Upload de arquivo</p>
                                        <p onClick={() => { setVisibleModalVincular(true) }} >Vincular arquivo existente</p>
                                        <p onClick={() => { setVisibleModalConectar(true) }}>Conectar URL</p>
                                    </MenuItens>

                                </ItemAction>
                                <ItemAction style={{ display: props.item?.variants?.length > 0 ? "flex" : "none" }}>
                                    {visibleOptions ?
                                        <IoIosArrowDown color={"#808080"} size={20} onClick={() => { setvisibleOptions(false) }} /> :
                                        <IoIosArrowForward color={"#808080"} size={20} onClick={() => { setvisibleOptions(true) }} />}

                                    {visibleOptions ?
                                        <label>Fechar variantes</label> :
                                        <label>Abrir variantes</label>}

                                </ItemAction>
                            </SectionActions>
                        </BoxProduct>

                        {
                            visibleOptions &&
                            <BoxOptions open={visibleOptions ? true : false}>
                                {
                                    props.item?.variants?.length == 0 ?
                                        <label>Sem variantes</label> :
                                        props?.item?.variants?.map((item) => {
                                            return (
                                                <CardVariente
                                                    setVisibleModalUpload={setVisibleModalUpload}
                                                    setVisibleModalVincular={setVisibleModalVincular}
                                                    setVisibleModalConectar={setVisibleModalConectar}
                                                    setSelectedVariante={setSelectedVariante}
                                                    getIconFile={getIconFile} item={item} />
                                            )
                                        })
                                }
                            </BoxOptions>
                        }


                    </Container>
                )
            })} */}
        </ContainerMain>
    )
}

export default TableProducts