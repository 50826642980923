import { TitleModal, BodyModal, ItemModal, Option } from "./styled";
import Modal from 'react-modal';
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { AiOutlineLoading, AiFillCheckCircle, AiFillCaretRight } from "react-icons/ai";
import { RiCheckboxBlankCircleFill } from "react-icons/ri";
import { MdError } from "react-icons/md";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { platforms } from "../../pages/home/dataCards";
import { BsTools } from "react-icons/bs";
//import { getTranslation } from "../../../../translations";
import nuvemshopImg from "../../../../assets/imgs/nuvem-shop-640x480.png";
import shopifyImg from "../../../../assets/imgs/shopify-logo.png";
import logoYampi from "../../../../assets/imgs/logo-yampi.png";
import { translations } from "../../../../utils/language";

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: "white",
        padding: "0px",
        margin: "0px"
    },
    overlay: {
        backgroundColor: "rgba(10,23,55,0.5)",
        zIndex: "99999999"

    }
};

const ModalNewComponent = () => {

    const [modalIsOpen, setModalIsOpen] = useState(null);
    const statusControlIntegration = useSelector(state => state.reducerControlIntegration)
    const language = useSelector((state) => state.reducerLanguage);
    const dispatch = useDispatch()

    //const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);

    useEffect(() => {
        if (statusControlIntegration.status == null) {
            setModalIsOpen(false)
        } else {
            setModalIsOpen(true)
        }

    }, [statusControlIntegration])




    const getIcon = () => {
        switch (statusControlIntegration.status) {
            case "error":
                return <MdError size={20} color={"red"} />
            case "success":
                return <AiFillCheckCircle size={20} color={"green"} />
            case "loading":
                return <AiOutlineLoading size={20} color={"green"} />
            case null:
                return <RiCheckboxBlankCircleFill size={12} color={"gray"} />
            default:
                break;
        }

    }

    const getTitle = () => {
        if (statusControlIntegration?.integration?.toLowerCase() == "nuvemshop") {
            return (
                <>
                    <span>
                        < img src={nuvemshopImg} />
                    </span>
                    <label style={{ color: "gray" }}>{language.data == "es"? "Tiendanube":"Nuvemshop"}</label>
                </>
            )

        }

        if (statusControlIntegration?.integration?.toLowerCase() == "yampi") {
            return (
                <>
                    <span>
                        < img src={logoYampi} />
                    </span>
                    <label style={{ color: "gray" }} >Yampi</label>
                </>
            )

        }

        if (statusControlIntegration?.integration?.toLowerCase() == "shopify") {
            return (
                <>
                    <span>
                        < img src={shopifyImg} />
                    </span>
                    <label style={{ color: "gray" }} >Shopify</label>
                </>
            )

        }


    }


    return (
        <Modal
            //isOpen={modalIsOpen}
            isOpen={modalIsOpen}
            style={customStyles}
            ariaHideApp={false}
        >

            <TitleModal>
                {getTitle()}
            </TitleModal>
            <BodyModal>

                <Option loading={false}>
                    <label>  {translations["acao"][language.data]}</label>
                    <label>  {translations["progresso"][language.data]}</label>
                    <div>  {translations["feedback"][language.data]}</div>
                </Option>

                <Option loading={statusControlIntegration.status == "loading" ? true : false}>
                    <label>  {translations["integracao"][language.data]}</label>
                    <label>1 de 1</label>
                    <div>
                        <span>
                            {getIcon()}
                        </span>
                    </div>
                </Option>
            </BodyModal>
        </Modal>
    )
}

export default ModalNewComponent
