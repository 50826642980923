import React, { useEffect, useState } from 'react'
import { BoxOptions, BoxProduct, Container, IconFile, ItemAction, ItemOption, MenuItens, Name, SectionActions, SectionAnexos, SectionImage, SectionInfor, SectionOptions } from './styled'

import { AiOutlinePaperClip, AiOutlinePlus, AiOutlineReload, } from "react-icons/ai";
import { IoIosArrowForward, IoIosArrowDown } from "react-icons/io";
import { BsFileEarmarkImageFill, BsFileEarmarkPdfFill, BsFillFileEarmarkPlayFill, BsFileEarmarkArrowDownFill, BsLink45Deg, BsFillFileEarmarkFill } from "react-icons/bs";
import ModalUploadAnexo from '../modal-upload-anexo';
import ModalConectarAnexo from '../modal-conectar-anexo';
import ModalVincularAnexo from '../modal-vincular-anexo';
import CardVariente from '../card-variante';
import ModalConfirmation from "../../../../components/Modal-Confirmation";
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

import IconUrl from "../../../../assets/imgs/icons/code.svg";
import IconDefault from "../../../../assets/imgs/icons/default.svg"
import IconDoc from "../../../../assets/imgs/icons/doc.svg";
import IconVideo from "../../../../assets/imgs/icons/videoplay.svg";
import IconImage from "../../../../assets/imgs/icons/image.svg";
import IconPdf from "../../../../assets/imgs/icons/pdf.svg";
import { FiCameraOff } from 'react-icons/fi';
import { translations } from '../../../../utils/language';

const CardProduct = (props) => {

    const [visibleOptions, setvisibleOptions] = useState(false)
    const [visibleModalUpload, setVisibleModalUpload] = useState(false)
    const [visibleModalVincular, setVisibleModalVincular] = useState(false)
    const [visibleModalConectar, setVisibleModalConectar] = useState(false)
    const [selectedVariante, setSelectedVariante] = useState("")
    const iconFile = { size: 20, color: "#808080" }
    const [deleteMedia, setDeleteMedia] = useState(null)

    const language = useSelector((state) => state.reducerLanguage);

    const [visibleMenu, setVisibleMenu] = useState(false)
    const [visibleMenuVariant, setVisibleMenuVariant] = useState(false)
    const [visibleModalConfirmation, setVisibleModalConfirmation] = useState(false)

    const statusRemoveLink = useSelector((state) => state.reducerControlRemoveLink);

    const dispatch = useDispatch()

    const clickMedia = (itemMedia) => {

        setDeleteMedia(itemMedia)
        setVisibleModalConfirmation(true)
    }

    const deleteMediaProduct = () => {
        dispatch({
            type: "DELETE_LINK_MEDIA_PRODUCTS",
            payload: {
                media_id: deleteMedia.id,
                product_id: props.item.products.id,
            },
        });
    }


    const getIconFile = (type, tooltip, media, active = true) => {
        switch (type?.toLowerCase()) {
            case "png": case "jpg": case "jpeg": case "gif": case "bmp": case "psd": case "svg": case "WEBP":
                return (
                    <IconFile onClick={() => { tooltip?.length > 0 && clickMedia(media) }}>
                        {/* <BsFileEarmarkImageFill color={iconFile.color} size={iconFile.size} /> */}
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M22.019 16.82L18.889 9.5C18.319 8.16 17.469 7.4 16.499 7.35C15.539 7.3 14.609 7.97 13.899 9.25L11.999 12.66C11.599 13.38 11.029 13.81 10.409 13.86C9.779 13.92 9.149 13.59 8.639 12.94L8.419 12.66C7.709 11.77 6.829 11.34 5.929 11.43C5.029 11.52 4.259 12.14 3.749 13.15L2.019 16.6C1.399 17.85 1.459 19.3 2.189 20.48C2.919 21.66 4.189 22.37 5.579 22.37H18.339C19.679 22.37 20.929 21.7 21.669 20.58C22.429 19.46 22.549 18.05 22.019 16.82ZM6.97 8.381C7.86643 8.381 8.72615 8.0249 9.36002 7.39102C9.99389 6.75715 10.35 5.89743 10.35 5.001C10.35 4.10457 9.99389 3.24485 9.36002 2.61098C8.72615 1.97711 7.86643 1.621 6.97 1.621C6.07357 1.621 5.21385 1.97711 4.57998 2.61098C3.94611 3.24485 3.59 4.10457 3.59 5.001C3.59 5.89743 3.94611 6.75715 4.57998 7.39102C5.21385 8.0249 6.07357 8.381 6.97 8.381Z" fill={active ? "#393939" : "#d3d3d3"} />
                        </svg>
                        {tooltip?.length > 0 &&
                            <label>
                                {tooltip}
                            </label>}
                    </IconFile>
                )
            case "pdf":
                return (
                    <IconFile onClick={() => { tooltip?.length > 0 && clickMedia(media) }} >
                        {/* <BsFileEarmarkPdfFill color={iconFile.color} size={iconFile.size} /> */}
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15.8 2.20999C15.39 1.79999 14.68 2.07999 14.68 2.64999V6.13999C14.68 7.59999 15.92 8.80999 17.43 8.80999C18.38 8.81999 19.7 8.81999 20.83 8.81999C21.4 8.81999 21.7 8.14999 21.3 7.74999C19.86 6.29999 17.28 3.68999 15.8 2.20999Z" fill={active ? "#393939" : "#d3d3d3"} />
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M17.61 10.19H20.5C21.05 10.19 21.5 10.64 21.5 11.19V16.43C21.5 20 19.01 22 15.93 22H8.07C4.99 22 2.5 20 2.5 16.43V7.57C2.5 4 4.99 2 8.07 2H12.31C12.86 2 13.31 2.45 13.31 3V5.89C13.31 8.26 15.24 10.19 17.61 10.19ZM8.88303 14.7561C8.88303 15.2554 8.71637 15.6714 8.38307 16.0043C8.05531 16.3314 7.55258 16.495 6.87485 16.495H5.75827V18.6209C5.75827 18.8303 5.58853 19 5.37914 19C5.16975 19 5 18.8303 5 18.6209V14C5 13.4477 5.44772 13 6 13H6.87485C7.53036 13 8.02754 13.1636 8.3664 13.4907C8.71082 13.8178 8.88303 14.2396 8.88303 14.7561ZM6.87485 15.8494C7.29704 15.8494 7.60813 15.7547 7.80811 15.5653C8.0081 15.3759 8.10809 15.1062 8.10809 14.7561C8.10809 14.0158 7.69701 13.6456 6.87485 13.6456H5.75827V15.8494H6.87485ZM11.6337 13C12.2669 13 12.8141 13.1234 13.2752 13.3702C13.7418 13.6112 14.0973 13.9584 14.3418 14.4118C14.5918 14.8651 14.7167 15.3989 14.7167 16.0129C14.7167 16.627 14.5918 17.1607 14.3418 17.6141C14.0973 18.0617 13.7418 18.406 13.2752 18.6471C12.8141 18.8824 12.2669 19 11.6337 19H10.8255C10.2732 19 9.82546 18.5523 9.82546 18V14C9.82546 13.4477 10.2732 13 10.8255 13H11.6337ZM11.6337 18.3544C12.3836 18.3544 12.9558 18.1506 13.3502 17.7432C13.7446 17.33 13.9418 16.7532 13.9418 16.0129C13.9418 15.2669 13.7418 14.6844 13.3419 14.2654C12.9474 13.8465 12.378 13.637 11.6337 13.637H10.5837V18.3544H11.6337ZM19 13.3185C19 13.1426 18.8574 13 18.6815 13H16.7169C16.1646 13 15.7169 13.4477 15.7169 14V18.6209C15.7169 18.8303 15.8867 19 16.0961 19C16.3055 19 16.4752 18.8303 16.4752 18.6209V16.297H18.2065C18.3824 16.297 18.525 16.1544 18.525 15.9785C18.525 15.8026 18.3824 15.66 18.2065 15.66H16.4752V13.637H18.6815C18.8574 13.637 19 13.4944 19 13.3185Z" fill={active ? "#393939" : "#d3d3d3"} />
                        </svg>
                        {tooltip?.length > 0 &&
                            <label>
                                {tooltip}
                            </label>}
                    </IconFile>
                )
            case "mp4": case "avi": case "mkv":
                return (
                    <IconFile onClick={() => { tooltip?.length > 0 && clickMedia(media) }}>
                        {/* <BsFillFileEarmarkPlayFill color={iconFile.color} size={iconFile.size} /> */}
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14.73 2H9.27V6.36H14.73V2ZM16.23 2V6.36H21.87C21.36 3.61 19.33 2.01 16.23 2ZM2 7.86V16.19C2 19.83 4.17 22 7.81 22H16.19C19.83 22 22 19.83 22 16.19V7.86H2ZM14.44 16.18L12.36 17.38C11.92 17.63 11.49 17.76 11.09 17.76C10.79 17.76 10.52 17.69 10.27 17.55C9.69 17.22 9.37 16.54 9.37 15.66V13.26C9.37 12.38 9.69 11.7 10.27 11.37C10.85 11.03 11.59 11.09 12.36 11.54L14.44 12.74C15.21 13.18 15.63 13.8 15.63 14.47C15.63 15.14 15.2 15.73 14.44 16.18ZM7.769 2C4.669 2.01 2.639 3.61 2.129 6.36H7.769V2Z" fill={active ? "#393939" : "#d3d3d3"} />
                        </svg>
                        {tooltip?.length > 0 &&
                            <label>
                                {tooltip}
                            </label>}
                    </IconFile>
                )
            case "url":
                console.log("teste", tooltip)
                return (
                    <IconFile onClick={() => { tooltip?.length > 0 ? clickMedia(media) : clickMedia(media) }}>
                        {/* <BsLink45Deg color={iconFile.color} size={iconFile.size} /> */}
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M21 10H3C2.45 10 2 10.45 2 11V16.19C2 16.39 2.01 16.59 2.03 16.79C2.23 19.87 4.13 21.77 7.21 21.97C7.41 21.99 7.61 22 7.81 22H16.19C16.39 22 16.59 21.99 16.79 21.97C19.87 21.77 21.77 19.87 21.97 16.79C21.99 16.59 22 16.39 22 16.19V11C22 10.45 21.55 10 21 10ZM8 16.15C8.34 16.65 8.8 17.06 9.33 17.33C9.71 17.51 9.86 17.96 9.67 18.34C9.54 18.6 9.27 18.75 9 18.75C8.89 18.75 8.77 18.72 8.67 18.67C7.89 18.29 7.23 17.7 6.76 16.99C6.36 16.39 6.36 15.61 6.76 15.01C7.23 14.3 7.89 13.71 8.67 13.33C9.04 13.14 9.49 13.29 9.67 13.66C9.86 14.04 9.71 14.49 9.33 14.67C8.8 14.94 8.34 15.35 8 15.85C7.94 15.94 7.94 16.06 8 16.15ZM17.45 16.99C16.97 17.7 16.31 18.29 15.54 18.67C15.4527 18.7143 15.3576 18.741 15.26 18.7484C15.1624 18.7558 15.0643 18.7438 14.9713 18.7131C14.8784 18.6825 14.7924 18.6337 14.7184 18.5697C14.6444 18.5056 14.5837 18.4276 14.54 18.34C14.4945 18.2522 14.4669 18.1562 14.4589 18.0576C14.4509 17.959 14.4626 17.8598 14.4933 17.7658C14.524 17.6718 14.5731 17.5849 14.6378 17.51C14.7025 17.4352 14.7814 17.374 14.87 17.33C15.41 17.06 15.87 16.65 16.2 16.15C16.2341 16.1074 16.2526 16.0545 16.2526 16C16.2526 15.9455 16.2341 15.8926 16.2 15.85C15.87 15.35 15.41 14.94 14.87 14.67C14.7814 14.626 14.7025 14.5648 14.6378 14.49C14.5731 14.4151 14.524 14.3282 14.4933 14.2342C14.4626 14.1402 14.4509 14.041 14.4589 13.9424C14.4669 13.8438 14.4945 13.7478 14.54 13.66C14.72 13.29 15.17 13.14 15.54 13.33C16.31 13.71 16.97 14.3 17.45 15.01C17.85 15.61 17.85 16.39 17.45 16.99ZM22 7.81V8C22 8.55 21.55 9 21 9L3 9.01C2.45 9.01 2 8.56 2 8.01V7.81C2 7.61 2.01 7.41 2.03 7.21C2.23 4.13 4.13 2.23 7.21 2.03C7.41 2.01 7.61 2 7.81 2H16.19C16.39 2 16.59 2.01 16.79 2.03C19.87 2.23 21.77 4.13 21.97 7.21C21.99 7.41 22 7.61 22 7.81Z" fill={active ? "#393939" : "#d3d3d3"} />
                        </svg>
                        {tooltip?.length > 0 &&
                            <label>
                                {tooltip}
                            </label>}
                    </IconFile>
                )

            default:
                return (
                    <IconFile onClick={() => { tooltip?.length > 0 && clickMedia(media) }}>
                        {/* <BsFillFileEarmarkFill color={iconFile.color} size={iconFile.size} /> */}
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15.8 2.20999C15.39 1.79999 14.68 2.07999 14.68 2.64999V6.13999C14.68 7.59999 15.92 8.80999 17.43 8.80999C18.38 8.81999 19.7 8.81999 20.83 8.81999C21.4 8.81999 21.7 8.14999 21.3 7.74999C19.86 6.29999 17.28 3.68999 15.8 2.20999Z" fill={active ? "#393939" : "#d3d3d3"} />
                            <path d="M20.5 10.19H17.61C15.24 10.19 13.31 8.26 13.31 5.89V3C13.31 2.45 12.86 2 12.31 2H8.07C4.99 2 2.5 4 2.5 7.57V16.43C2.5 20 4.99 22 8.07 22H15.93C19.01 22 21.5 20 21.5 16.43V11.19C21.5 10.64 21.05 10.19 20.5 10.19Z" fill={active ? "#393939" : "#d3d3d3"} />
                        </svg>
                        {tooltip?.length > 0 &&
                            <label>
                                {tooltip}
                            </label>}
                    </IconFile>
                )
        }
    }



    const closeMenu = (event) => {
        setVisibleMenu(false);
    };

    useEffect(() => {

        if (visibleMenu) {
            window.addEventListener("mouseup", closeMenu);
            return () => { window.removeEventListener("mouseup", closeMenu); };
        }
    }, [visibleMenu]);

    const closeMenuVariant = (event) => {
        setVisibleMenuVariant(false);
    };

    useEffect(() => {
        if (visibleMenuVariant) {
            window.addEventListener("mouseup", closeMenuVariant);
            return () => { window.removeEventListener("mouseup", closeMenuVariant); };
        }
    }, [visibleMenu]);




    const updateProduct = () => {
        dispatch({
            type: "SAGA_UPDATE_DATA_PRODUCT",
            payload: {
                integration: JSON.parse(localStorage.getItem("depoimentos@login"))?.integration,
                product: props.item.products,

            }
        })
    }

    const cliqueCard = (sync = true) => {

        if (sync) {
            setvisibleOptions(!visibleOptions);
        } else {
            updateProduct();
        }
    }

    return (
        <Container >
            <ModalUploadAnexo goSearch={props.goSearch} search={props.search} medias={props.item.media} getIconFile={getIconFile} item={props.item.products} selectedVariante={selectedVariante} visible={visibleModalUpload} setVisible={setVisibleModalUpload} />
            <ModalConectarAnexo search={props.search} medias={props.item.media} getIconFile={getIconFile} item={props.item.products} selectedVariante={selectedVariante} visible={visibleModalConectar} setVisible={setVisibleModalConectar} />
            <ModalVincularAnexo search={props.search} medias={props.item.media} getIconFile={getIconFile} item={props.item.products} selectedVariante={selectedVariante} visible={visibleModalVincular} setVisible={setVisibleModalVincular} />
            <ModalConfirmation
                confirmation={deleteMediaProduct}
                setVisibleModal={setVisibleModalConfirmation}
                visible={visibleModalConfirmation}
                title={`Deseja desvincular ${translations[`medias-arquivo`][language?.data]} ${deleteMedia?.type == "url" ? deleteMedia?.media : deleteMedia?.media_name} ${language?.data == "es" ? "del" : "do"} produto ${props.item.products?.name} ?`}
            />
            <BoxProduct >

                <SectionImage onClick={() => cliqueCard()}>
                    {props.item.products.image_url?.length > 0 ?
                        < img src={props.item.products.image_url} /> :
                        <FiCameraOff size={40} color='gray' />}

                </SectionImage>
                <SectionInfor onClick={() => cliqueCard()}>
                    <Name>{props.item.products?.name}</Name>
                    <SectionAnexos>
                        {props.item?.media?.map((item) => {
                            return (
                                item?.type == "url" ?
                                    getIconFile("url", item?.media, item) :
                                    getIconFile(item?.media_name?.split(".")[item?.media_name?.split(".").length - 1], item?.media_name, item)
                            )
                        })}
                    </SectionAnexos>
                </SectionInfor>
                <SectionActions >
                    <ItemAction
                        //style={{ display: props.item?.variants?.length == 0 ? "flex" : "none" }}
                        border={true}
                        onClick={() => { cliqueCard(false) }} >
                        <AiOutlineReload size={20} color="#1B76FF" />
                        <label>{translations[`products-sincronizar-dados`][language?.data]}</label>
                    </ItemAction>

                    <ItemAction
                        style={{ position: "relative", display: props.item?.variants?.length == 0 ? "flex" : "none" }}
                        border={true} onClick={() => { setVisibleMenu(!visibleMenu) }} >
                        <AiOutlinePaperClip size={20} color="#1B76FF" />
                        <label>Anexar arquivo</label>

                        <MenuItens visible={visibleMenu}>
                            <p onClick={() => { setVisibleModalUpload(true) }}>{translations[`products-upload-arquivos`][language?.data]}</p>
                            <p onClick={() => { setVisibleModalVincular(true) }} >{translations[`products-vincular-arquivo`][language?.data]}</p>
                            <p onClick={() => { setVisibleModalConectar(true) }}>Conectar URL</p>
                        </MenuItens>

                    </ItemAction>
                    <ItemAction style={{ display: props.item?.variants?.length > 0 ? "flex" : "none" }}>
                        {visibleOptions ?
                            <IoIosArrowDown color={"#808080"} size={20} onClick={() => { setvisibleOptions(false) }} /> :
                            <IoIosArrowForward color={"#808080"} size={20} onClick={() => { setvisibleOptions(true) }} />}

                        {visibleOptions ?
                            <label>{translations[`products-fechar-variantes`][language?.data]}</label> :
                            <label>Abrir variantes</label>}

                    </ItemAction>
                </SectionActions>
            </BoxProduct>

            {
                visibleOptions &&
                <BoxOptions open={visibleOptions ? true : false}>
                    {
                        props.item?.variants?.length == 0 ?
                            <label>Sem variantes</label> :
                            props?.item?.variants?.map((item) => {
                                return (
                                    <CardVariente
                                        setVisibleModalUpload={setVisibleModalUpload}
                                        setVisibleModalVincular={setVisibleModalVincular}
                                        setVisibleModalConectar={setVisibleModalConectar}
                                        setSelectedVariante={setSelectedVariante}
                                        getIconFile={getIconFile} item={item} />
                                )
                            })
                    }
                </BoxOptions>
            }


        </Container>
    )
}

export default CardProduct